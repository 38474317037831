import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import MenuIcon from '../../components/Icons/MenuIcon';
import CloseIcon from '../../components/Icons/CloseIcon';

import ProfileButton from './ProfileButton';

import './style.scss';

export interface AppBarProps {
  openNav: boolean;
  onOpenNav: () => void;
  onCloseNav: () => void;
  userName?: string;
  title?: string;
  showProfile?: boolean;
}

const AppBar: React.FC<AppBarProps> = ({
  openNav,
  onOpenNav,
  onCloseNav,
  userName = '',
  title = '',
  showProfile = true,
}) => (
  <MuiAppBar
    sx={{ zIndex: (theme) => theme.zIndex.drawer + 2 }}
  >
    <Toolbar
      className="appbar-toolbar"
      sx={{ minHeight: { xs: 82 } }}
    >
      <IconButton
        className="appbar-open-nav"
        onClick={openNav ? onCloseNav : onOpenNav}
        sx={{
          width: 42,
        }}
      >
        {openNav ? <CloseIcon /> : <MenuIcon />}
      </IconButton>

      <Typography className="appbar-user" variant="h5">
        {title ?? ''}
        {!!userName
          ? userName.split(' ').slice(0, 2).join(' ')
          : ''}
      </Typography>

      {showProfile && (
        <Box className="appbar-logo">
          <ProfileButton />
        </Box>
      )}
    </Toolbar>
  </MuiAppBar>
);

export default AppBar;
