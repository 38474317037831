const DignityIcon: React.FC = () => (
  <svg
    width="82"
    height="57"
    viewBox="0 0 82 57"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M40.56 38.08C51.0755 38.08 59.6 29.5555 59.6 19.04C59.6 8.5245 51.0755 0 40.56 0C30.0445 0 21.52 8.5245 21.52 19.04C21.52 29.5555 30.0445 38.08 40.56 38.08Z"
      fill="#FCB542"
    />
    <path
      d="M54.0099 25.6199C58.7596 25.6199 62.6099 21.7696 62.6099 17.0199C62.6099 12.2703 58.7596 8.41992 54.0099 8.41992C49.2603 8.41992 45.4099 12.2703 45.4099 17.0199C45.4099 21.7696 49.2603 25.6199 54.0099 25.6199Z"
      fill="white"
      stroke="#D34F7A"
      strokeWidth="2.94"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M27.12 28.1399C31.8697 28.1399 35.72 24.2896 35.72 19.5399C35.72 14.7903 31.8697 10.9399 27.12 10.9399C22.3704 10.9399 18.52 14.7903 18.52 19.5399C18.52 24.2896 22.3704 28.1399 27.12 28.1399Z"
      fill="white"
      stroke="#4FB3E2"
      strokeWidth="2.94"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M19.4099 55.4099C18.8097 50.5866 18.5124 45.7304 18.5199 40.8699C18.5199 40.8699 -1.9201 28.8699 1.9599 19.5499C1.9599 19.5499 4.2199 17.9299 6.6399 20.5499C9.0599 23.1699 16.6399 31.0599 24.0899 31.0599C31.5399 31.0599 37.1599 30.1299 40.9699 35.1799C42.7063 37.412 43.6241 40.1725 43.5699 42.9999C43.5692 43.0515 43.5564 43.1021 43.5327 43.1478C43.5089 43.1935 43.4747 43.233 43.4329 43.2631C43.3911 43.2932 43.3428 43.3132 43.292 43.3213C43.2411 43.3294 43.189 43.3256 43.1399 43.3099C41.8383 42.8979 40.498 42.6197 39.1399 42.4799C38.9314 42.4556 38.7201 42.4762 38.5202 42.5404C38.3203 42.6046 38.1365 42.7108 37.9811 42.852C37.8257 42.9932 37.7024 43.166 37.6194 43.3588C37.5364 43.5517 37.4956 43.76 37.4999 43.9699L37.8299 55.4099"
      fill="white"
    />
    <path
      d="M19.4099 55.4099C18.8097 50.5866 18.5124 45.7304 18.5199 40.8699C18.5199 40.8699 -1.9201 28.8699 1.9599 19.5499C1.9599 19.5499 4.2199 17.9299 6.6399 20.5499C9.0599 23.1699 16.6399 31.0599 24.0899 31.0599C31.5399 31.0599 37.1599 30.1299 40.9699 35.1799C42.7063 37.412 43.6241 40.1725 43.5699 42.9999C43.5692 43.0515 43.5564 43.1021 43.5327 43.1478C43.5089 43.1935 43.4747 43.233 43.4329 43.2631C43.3911 43.2932 43.3428 43.3132 43.292 43.3213C43.2411 43.3294 43.189 43.3255 43.1399 43.3099C41.8383 42.8979 40.498 42.6197 39.1399 42.4799C38.9314 42.4556 38.7201 42.4762 38.5202 42.5404C38.3203 42.6046 38.1365 42.7108 37.9811 42.852C37.8257 42.9932 37.7024 43.166 37.6194 43.3588C37.5364 43.5517 37.4956 43.76 37.4999 43.9699L37.8299 55.4099"
      stroke="#4FB3E2"
      strokeWidth="2.94"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M61.5699 55.41C62.193 49.744 62.5267 44.0499 62.5699 38.35C62.5699 38.35 83.0499 26.4099 79.1499 17.0099C79.1499 17.0099 76.8899 15.3999 74.4699 18.0099C72.0499 20.6199 64.4699 28.5099 57.0199 28.5099C49.5699 28.5099 32.5299 29.3199 37.9499 32.5099C41.9499 34.8699 43.3499 40.6899 43.3299 41.5799L43.6299 55.41"
      fill="white"
    />
    <path
      d="M61.5699 55.41C62.193 49.744 62.5267 44.0499 62.5699 38.35C62.5699 38.35 83.0499 26.4099 79.1499 17.0099C79.1499 17.0099 76.8899 15.3999 74.4699 18.0099C72.0499 20.6199 64.4699 28.5099 57.0199 28.5099C49.5699 28.5099 32.5299 29.3199 37.9499 32.5099C41.9499 34.8699 43.3499 40.6899 43.3299 41.5799L43.6299 55.41"
      stroke="#D34F7A"
      strokeWidth="2.94"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);

export default DignityIcon;
