import { ReactNode, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import {
  motion,
  useMotionTemplate,
  useTransform,
  useScroll,
  AnimatePresence,
} from 'framer-motion';

import BISIONLogo from '../../Misc/BISIONLogo';

import './style.scss';

const HeaderStartImg: React.FC = () => (
  <svg
    width="65"
    height="68"
    viewBox="0 0 65 68"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="-0.562263"
      y="55.3104"
      width="37.874"
      height="9.82569"
      rx="4.91285"
      transform="rotate(-58.9194 -0.562263 55.3104)"
      fill="#4FB3E2"
    />
    <rect
      width="37.874"
      height="9.82569"
      rx="4.91285"
      transform="matrix(-0.484363 -0.874867 -0.874867 0.484363 65.9034 56.2432)"
      fill="#4FB3E2"
    />
    <circle
      cx="33.5942"
      cy="10.6801"
      r="10.6801"
      fill="#4FB3E2"
    />
    <rect
      x="16"
      y="24"
      width="34"
      height="44"
      rx="5"
      fill="#4FB3E2"
    />
    <path
      d="M33.167 36.7396L16.0789 31.1859V51.6917L33.167 58.0998V36.7396Z"
      fill="#FCB542"
    />
    <path
      d="M33.167 36.7396L50.2552 31.1859V51.6917L33.167 58.0998V36.7396Z"
      fill="#FCCB83"
    />
    <rect
      x="-1"
      y="52.7805"
      width="26.4866"
      height="9.82569"
      rx="4.91285"
      transform="rotate(-25.9321 -1 52.7805)"
      fill="#4FB3E2"
    />
    <rect
      width="26.4866"
      height="9.82569"
      rx="4.91285"
      transform="matrix(-0.899313 -0.437305 -0.437305 0.899313 66 52.3533)"
      fill="#4FB3E2"
    />
  </svg>
);

const HeaderDownImg: React.FC = () => (
  <svg
    width="19"
    height="28"
    viewBox="0 0 19 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.89 0.75C17.89 5.48 9.32005 9.31999 9.32005 9.31999C9.32005 9.31999 0.749977 5.48 0.749977 0.75"
      stroke="#F29C00"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M17.89 9.31995C17.89 14.0499 9.32005 17.8799 9.32005 17.8799C9.32005 17.8799 0.749977 14.0499 0.749977 9.31995"
      stroke="#F29C00"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M17.89 17.88C17.89 22.61 9.32005 26.45 9.32005 26.45C9.32005 26.45 0.749977 22.61 0.749977 17.88"
      stroke="#F29C00"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);

const FadeOnPresence: React.FC<{
  children: ReactNode;
  className?: string;
  delay?: number;
}> = ({ children, className = '', delay = 0.05 }) => (
  <motion.div
    className={className}
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    transition={{
      delay,
      ease: 'easeIn',
      duration: 0.35,
    }}
  >
    {children}
  </motion.div>
);

const HomeHeader: React.FC<{
  fadeAt: number;
  hideAt: number;
}> = ({ fadeAt, hideAt }) => {
  const [begin, setBegin] = useState(false);

  const { scrollY } = useScroll();

  const y = useTransform(
    scrollY,
    [0, hideAt - 140, hideAt],
    [0, 0, -140]
  );
  const transform = useMotionTemplate`translate(0, ${y}px)`;

  useEffect(() => {
    setBegin(scrollY.get() >= fadeAt - 140);
    scrollY.onChange((v) => setBegin(v >= fadeAt - 140));
  }, [fadeAt, scrollY]);

  return (
    <motion.div
      className="home-header"
      style={{ transform }}
    >
      <AnimatePresence
        exitBeforeEnter={false}
        initial={false}
      >
        <Paper component="header" elevation={6} square>
          <Box className="home-header-container">
            {!begin && (
              <Box className="home-header-logo">
                <FadeOnPresence>
                  <BISIONLogo />
                </FadeOnPresence>
              </Box>
            )}
            {begin && (
              <Box className="header-start-img">
                <FadeOnPresence>
                  <HeaderStartImg />
                </FadeOnPresence>
              </Box>
            )}

            <Box className="home-header-text">
              {!begin && (
                <FadeOnPresence delay={0.2}>
                  <Typography
                    variant="h4"
                    className="home-header-title"
                  >
                    BISION
                  </Typography>
                  <Typography
                    variant="h6"
                    className="home-header-subtitle"
                  >
                    la plataforma para tu <br />
                    <span>bien &amp; estar</span>
                  </Typography>
                </FadeOnPresence>
              )}
              {begin && (
                <FadeOnPresence delay={0.2}>
                  <Typography variant="h5">
                    antes de empezar...
                  </Typography>

                  <Box className="home-header-down">
                    <HeaderDownImg />
                  </Box>
                </FadeOnPresence>
              )}
            </Box>
          </Box>
        </Paper>
      </AnimatePresence>
    </motion.div>
  );
};

export default HomeHeader;
