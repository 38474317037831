import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import {
  motion,
  MotionValue,
  useTransform,
} from 'framer-motion';
import { useEffect, useState } from 'react';

import { arcPathD, constrain } from '../../../utils';

import './style.scss';

const STEP_WIDTH = 48;
const PI = Math.PI;

const MultipleOptionStepSelected: React.FC = () => (
  <svg
    className="mo-stepper-step-selected"
    width="22"
    height="15"
    viewBox="0 0 20 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <motion.path
      d="M1.92432 7.37884L7.22724 12.6818L18.5906 1.31836"
      stroke="#8ACFBB"
      strokeWidth="3"
      initial={{ pathLength: 0 }}
      animate={{
        pathLength: 1,
        transition: {
          duration: 0.35,
          ease: 'easeIn',
          delay: 0.05,
        },
      }}
    />
  </svg>
);

const MultipleOptionStepperLine: React.FC<{
  index: number;
  steps: number;
  scrollY: MotionValue;
}> = ({ index, steps, scrollY }) => {
  const [amt, setAmt] = useState(0);

  const amtMotion = useTransform(scrollY, (v) =>
    constrain(v * steps - index, 0, 1)
  );

  useEffect(() => {
    amtMotion.onChange((v) => setAmt(v));

    return () => {
      amtMotion.clearListeners();
    };
  }, [amtMotion]);

  return (
    <motion.path
      className="mo-stepper-line"
      d={arcPathD(
        STEP_WIDTH * (index + 0.5) + 1,
        25,
        STEP_WIDTH * 0.5,
        index % 2 === 0 ? PI * (1 - amt) : PI,
        index % 2 === 0 ? PI : PI * (1 + amt)
      )}
    />
  );
};

export interface MultipleOptionStepperProps {
  selection: boolean[];
  scrollY: MotionValue;
}

const MultipleOptionStepper: React.FC<
  MultipleOptionStepperProps
> = ({ selection, scrollY }) => {
  const width = STEP_WIDTH * selection.length;

  return (
    <Paper
      className="mo-stepper"
      sx={{
        zIndex: '2',
        backgroundColor: '#202020',
        borderRadius: '0 0 10px 10px',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      }}
    >
      <Box
        className="mo-stepper-steps"
        sx={{ width: `${width}px` }}
      >
        {selection.map((selected, i) =>
          selected ? (
            <MultipleOptionStepSelected
              key={`mo-step-${i}`}
            />
          ) : (
            <motion.div
              className="mo-stepper-step"
              key={`mo-step-${i}`}
            />
          )
        )}

        <svg
          className="mo-stepper-path"
          width={width + 2}
          viewBox={`0 0 ${width + 2} 50`}
        >
          {selection.map((_, i) => (
            <MultipleOptionStepperLine
              key={`step-path-${i}`}
              index={i}
              steps={selection.length}
              scrollY={scrollY}
            />
          ))}
        </svg>
      </Box>
    </Paper>
  );
};

export default MultipleOptionStepper;
