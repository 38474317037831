import { createContext, useState } from 'react';
import jwtDecode, { JwtPayload } from 'jwt-decode';

import authAPI from '../../api/auth';

export type AuthProviderType = {
  authToken: string;
  checkAuth: () => boolean;
  registerEmail: (email: string) => Promise<string>;
  loginWithToken: (token: string) => Promise<void>;
};

export const AuthContext = createContext<AuthProviderType>(
  null!
);

const storeToken = (token: string) =>
  localStorage.setItem('token', token);

const getToken = () => localStorage.getItem('token');

export const AuthProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [authToken, setAuthToken] = useState(
    getToken() ?? ''
  );

  const registerEmail = async (email: string) => {
    const response = await authAPI.registerEmail(email);
    return response.data.bypassToken;
  };

  const loginWithToken = async (token: string) => {
    try {
      await authAPI.verifyToken(token);
    } catch (e) {}

    storeToken(token);
    setAuthToken(token);
  };

  const checkAuth = () => {
    if (!authToken) return false;

    const decoded = jwtDecode<JwtPayload>(authToken);
    return decoded.exp! >= Date.now() / 1000;
  };

  return (
    <AuthContext.Provider
      value={{
        checkAuth,
        registerEmail,
        loginWithToken,
        authToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
