import { useEffect } from 'react';

export const useWindowResize = (cb: () => void) => {
  useEffect(() => {
    cb();
    window.addEventListener('resize', cb);
    return () => {
      window.removeEventListener('resize', cb);
    };
  }, [cb]);
};
