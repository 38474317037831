import * as yup from 'yup';

yup.setLocale({
  mixed: {
    required: 'Campo requerido',
  },
  string: {
    email: 'Correo electrónico inválido',
  },
});

export const loginFormSchema = yup.object().shape({
  email: yup.string().email().required(),
});

export const multipleOptionFormSchema = yup.object().shape({
  optionSelected: yup.string().required(),
  otherSelected: yup.boolean(),
  text: yup
    .string()
    .when('otherSelected', (otherSelected, schema) =>
      otherSelected ? schema.required() : schema
    ),
});

export const ratingScaleFormSchema = yup.object().shape({
  ratingScales: yup.array().of(
    yup.object().shape({
      scaleId: yup.string().required(),
      ratingScaleLabelId: yup.string().required(),
    })
  ),
});

export const openTextFormSchema = yup.object().shape({
  value: yup.string().required(),
});

export const openTextNumberFormSchema = yup.object().shape({
  value: yup.number().required(),
});
