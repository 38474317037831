import { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import questionnaireAPI from '../../../api/questionnaires';

import InfoIcon from '../../Icons/InfoIcon';

import { Level1, Level2 } from '../../../types';
import { toPercentage } from '../../../utils';

const ContinueIcon: React.FC = () => (
  <svg
    width="8"
    height="15"
    viewBox="0 0 8 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 1L6 7.50254L1 14"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
  </svg>
);

const SubLevelGoButton: React.FC<{
  level1: Level1;
  level2: Level2;
  showScore: boolean;
  score: number;
}> = ({ level1, level2, showScore, score }) => {
  const questions = level1.questions.filter(
    (question) => question.level2.id === level2.id
  );
  const completedCount = questions.reduce(
    (prev, question) =>
      question.answered ? prev + 1 : prev,
    0
  );

  const progress = completedCount / questions.length;

  return (
    <Button
      className="sublevel-go-button"
      variant="contained"
      component={Link}
      disableElevation
      to={`/dashboard/evaluations/${level1.id}/${level2.id}`}
      sx={{
        backgroundColor:
          progress === 0
            ? '#4FB3E2'
            : progress === 1.0
            ? '#8ACFBB !important'
            : '#F29C00',
      }}
      endIcon={progress < 1.0 ? <ContinueIcon /> : null}
      disabled={progress === 1.0}
    >
      <Typography
        component="span"
        sx={{
          fontSize: showScore ? '0.8125rem' : '1rem',
        }}
      >
        {showScore
          ? `Resultado: ${toPercentage(score)}`
          : progress === 0.0
          ? 'Comenzar'
          : progress === 1.0
          ? 'Completado'
          : 'Continuar'}
      </Typography>
    </Button>
  );
};

export interface Level2RowProps {
  level1: Level1;
  level2: Level2;
  onSelectLevel: (level2: Level2) => void;
  onDeselectLevel: () => void;
  showResults: boolean;
}

const Level2Row: React.FC<Level2RowProps> = ({
  level1,
  level2,
  onSelectLevel,
  onDeselectLevel,
  showResults,
}) => {
  const [score, setScore] = useState(0);

  useEffect(() => {
    if (!showResults) {
      return;
    }

    (async () => {
      const results =
        await questionnaireAPI.getLevelResults(level2.id);
      if (results.length > 0) {
        setScore(results[0].score);
      }
    })();
  }, [level2.id, showResults]);

  return (
    <Box className="sublevel-summary-row">
      <Box
        className="sublevel-info"
        onMouseDown={() => onSelectLevel(level2)}
        onMouseUp={() => onDeselectLevel()}
        onTouchStart={() => onSelectLevel(level2)}
        onTouchEnd={() => onDeselectLevel()}
      >
        <InfoIcon />
      </Box>
      <Typography variant="h6">{level2.name.es}</Typography>
      <SubLevelGoButton
        level1={level1}
        level2={level2}
        showScore={showResults}
        score={score}
      />
    </Box>
  );
};

export default Level2Row;
