import { useContext, useEffect, useState } from 'react';

import { Outlet } from 'react-router-dom';
import Box from '@mui/material/Box';
import Container from '@mui/system/Container';

import { QuestionnaireContext } from '../../providers/QuestionnaireProvider';

import AppBar from '../AppBar';
import MenuNav from '../MenuNav';
import BottomNav from '../BottomNav';

const DashboardLayout: React.FC = () => {
  const [openNav, setOpenNav] = useState(false);
  const [userName, setUserName] = useState('');

  const { questionnaire } = useContext(
    QuestionnaireContext
  );

  useEffect(() => {
    if (!questionnaire) return;
    setUserName(questionnaire.profile);
  }, [questionnaire]);

  return (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <AppBar
        openNav={openNav}
        onOpenNav={() => {
          setOpenNav(true);
        }}
        onCloseNav={() => {
          setOpenNav(false);
        }}
        userName={userName}
      />
      <MenuNav
        open={openNav}
        onClose={() => {
          setOpenNav(false);
        }}
      />
      <Container
        component="main"
        maxWidth="md"
        disableGutters
        sx={{
          flex: 1,
          paddingTop: '82px',
          paddingBottom: '86px',
        }}
      >
        <Outlet />
      </Container>
      <BottomNav />
    </Box>
  );
};

export default DashboardLayout;
