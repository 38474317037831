import { useState } from 'react';

import {
  useForm,
  Controller,
  SubmitHandler,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { AnimatePresence, motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { loginFormSchema } from '../../../schemas';

import { useAuth } from '../../../hooks';

import './style.scss';

interface LoginFormInput {
  email: string;
}

const SubmitIcon: React.FC = () => (
  <svg
    width="24"
    height="20"
    viewBox="0 0 24 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.46997 6.97997C5.57907 6.63744 5.71277 6.30324 5.87 5.97997C6.78775 4.06502 8.35862 2.54025 10.3 1.67992C12.4722 0.724158 14.9339 0.664987 17.1494 1.51525C19.365 2.36551 21.155 4.05642 22.13 6.21996C23.0851 8.39331 23.1429 10.856 22.2906 13.0718C21.4384 15.2875 19.7453 17.0767 17.58 18.0499C15.4077 19.0019 12.9475 19.0581 10.7341 18.206C8.52076 17.354 6.73322 15.6627 5.76001 13.4999C5.64286 13.2536 5.54258 12.9998 5.45996 12.74"
      stroke="#4FB3E2"
      strokeWidth="1.84"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M18.22 9.87988H0.769989"
      stroke="#4FB3E2"
      strokeWidth="1.54"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M13.13 3.6001L12.08 4.7201L17.63 9.8801L12.08 15.0401L13.13 16.1701L19.89 9.8801L13.13 3.6001Z"
      fill="#4FB3E2"
    />
  </svg>
);

const LoginForm: React.FC = () => {
  const [emailSent, setEmailSent] = useState(false);

  const navigate = useNavigate();

  const auth = useAuth();
  const { handleSubmit, control } = useForm<LoginFormInput>(
    {
      defaultValues: {
        email: '',
      },
      resolver: yupResolver(loginFormSchema),
    }
  );

  const onSubmit: SubmitHandler<LoginFormInput> = async (
    data
  ) => {
    const token = await auth.registerEmail(data.email);
    setEmailSent(true);
    navigate(`email/verify?token=${token}`);
  };

  return (
    <AnimatePresence exitBeforeEnter>
      {emailSent && (
        <Box
          key="login-email-sent"
          className="login-email-sent"
          component={motion.div}
          initial={{ opacity: 0.15 }}
          animate={{ opacity: 1.0 }}
        >
          <Typography
            className="login-email-sent-title"
            variant="h6"
          >
            Revisa tu correo.
          </Typography>
          <Typography className="login-email-sent-description">
            ¡Gracias! Se te ha enviado un mail de acceso.
            <br />
            <br />
            Continúa desde tu correo electrónico.
          </Typography>
        </Box>
      )}

      {!emailSent && (
        <Box
          key="login-form"
          className="login-form"
          component={motion.form}
          onSubmit={handleSubmit(onSubmit)}
          initial={{ opacity: 1.0 }}
          exit={{ opacity: 0.15 }}
        >
          <Controller
            name="email"
            control={control}
            defaultValue=""
            render={({ field, fieldState: { error } }) => (
              <TextField
                className="email-field"
                fullWidth
                variant="standard"
                label="Correo electrónico"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton type="submit">
                        <SubmitIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={!!error}
                helperText={error ? error.message : null}
                {...field}
              />
            )}
          />
        </Box>
      )}
    </AnimatePresence>
  );
};

export default LoginForm;
