const StartIcon: React.FC = () => (
  <svg
    width="61"
    height="42"
    viewBox="0 0 61 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M53.41 23.9799H49.05C48.4252 24.0162 47.8056 23.8473 47.2856 23.4989C46.7657 23.1505 46.3739 22.6416 46.17 22.0499C45.7 20.9099 45.17 19.7799 44.75 18.6199C44.46 17.8599 44.15 17.6199 43.36 18.1599C41.1 19.7999 38.81 21.4199 36.49 22.9799C35.68 23.5199 35.75 23.9799 36.23 24.6799C38.0233 27.2399 39.79 29.8132 41.53 32.3999C43.13 34.7999 42.42 36.5799 39.58 37.2699C34.93 38.4099 30.28 39.5399 25.58 40.6299C23.17 41.1899 21.58 40.0399 21.75 37.9299C21.7934 37.3042 22.0557 36.7138 22.4909 36.2622C22.9261 35.8105 23.5063 35.5265 24.13 35.4599C26.95 34.7799 29.78 34.1199 32.6 33.4599C33.01 33.3599 33.6 33.2399 33.72 32.9599C33.98 32.4199 33.33 32.1599 33.01 31.8499C30.84 29.7599 28.62 27.7199 26.46 25.6099C24.1 23.3099 24.16 20.8799 26.56 18.6099C29.77 15.6099 32.96 12.5399 36.22 9.55991C37.14 8.72991 37.16 8.2099 36.39 7.19989C35.9611 6.52567 35.3508 5.98603 34.6291 5.64289C33.9074 5.29974 33.1036 5.16702 32.31 5.25992C30.3746 5.35992 28.4353 5.35992 26.5 5.25992C24.72 5.25992 23.74 4.34992 23.68 2.81992C23.6398 2.46727 23.6758 2.11009 23.7855 1.77253C23.8952 1.43497 24.076 1.12487 24.3159 0.863225C24.5557 0.60158 24.8489 0.394482 25.1757 0.255894C25.5025 0.117307 25.8552 0.0504708 26.21 0.0599106C29.49 -0.0600894 32.77 0.00991055 36.05 0.0599106C36.5362 0.105814 37.0062 0.258734 37.4263 0.507695C37.8464 0.756656 38.2062 1.09549 38.48 1.49991C41.48 5.12991 44.59 8.72991 47.63 12.3699C48.5219 13.5926 49.1641 14.979 49.52 16.4499C49.5792 16.8174 49.713 17.1689 49.9131 17.4829C50.1132 17.7968 50.3753 18.0664 50.6835 18.2753C50.9916 18.4842 51.3392 18.6278 51.7049 18.6974C52.0706 18.7669 52.4467 18.761 52.81 18.6799C54.3684 18.5799 55.9316 18.5799 57.49 18.6799C59.34 18.6799 60.56 19.8099 60.58 21.3099C60.6 22.8099 59.36 23.9499 57.41 23.9999H53.41V23.9799Z"
      fill="white"
    />
    <path
      d="M7.19004 41.8801C6.61972 41.8939 6.05926 41.73 5.58625 41.411C5.11324 41.0921 4.75111 40.634 4.55005 40.1001C4.29818 39.4693 4.26783 38.7716 4.46402 38.1213C4.66021 37.4709 5.07131 36.9064 5.63004 36.5201C9.44004 33.2801 13.2201 30.0201 17.0201 26.7601C18.2801 25.6801 19.5501 24.5901 20.8001 23.5001C21.5401 22.8601 22.1 22.4401 22.3601 23.9001C22.6407 25.2856 23.3849 26.5342 24.47 27.4401C25.25 28.1301 25.36 28.6201 24.47 29.3201C19.34 33.2401 14.2501 37.1901 9.15006 41.1401C8.58956 41.5846 7.90442 41.8432 7.19004 41.8801Z"
      fill="white"
    />
    <path
      d="M58.1501 7.12012C58.1156 8.53903 57.5189 9.88611 56.4912 10.8651C55.4635 11.844 54.089 12.3746 52.6701 12.3401C51.2512 12.3056 49.9041 11.7089 48.9251 10.6812C47.9462 9.65351 47.4156 8.27902 47.4501 6.86011C47.5194 5.44779 48.129 4.11618 49.1527 3.14081C50.1765 2.16544 51.5361 1.62098 52.9501 1.62012C54.363 1.67734 55.6976 2.28452 56.6692 3.31207C57.6407 4.33963 58.1721 5.70618 58.1501 7.12012Z"
      fill="white"
    />
    <path
      d="M19.7 12.9499C17.82 12.9499 15.93 12.9499 14.05 12.9499C12.82 12.9499 11.73 12.6099 11.76 11.0799C11.79 9.54986 12.88 9.36986 14.03 9.35986C17.85 9.35986 21.67 9.35986 25.49 9.35986C26.7 9.35986 27.83 9.67988 27.78 11.2199C27.73 12.7599 26.66 12.9299 25.51 12.9499C23.6 12.9699 21.7 12.9499 19.7 12.9499Z"
      fill="white"
    />
    <path
      d="M13.9 18.79H8.09002C6.88002 18.79 5.9 18.35 5.86 17.01C5.82 15.67 6.85999 15.26 7.93999 15.25C11.94 15.25 15.9167 15.25 19.87 15.25C20.97 15.25 21.96 15.68 21.94 17.01C21.92 18.34 20.89 18.75 19.71 18.76C17.77 18.8 15.84 18.79 13.9 18.79Z"
      fill="white"
    />
    <path
      d="M7.94003 24.6199H2.14004C0.990044 24.6199 0.030029 24.1799 2.89783e-05 22.9399C-0.029971 21.6999 1.00004 21.1899 2.20004 21.1899H13.9601C15.1101 21.1899 16.0801 21.5299 16.0901 22.8299C16.1001 24.1299 15.0901 24.6199 13.9001 24.6199H7.94003Z"
      fill="white"
    />
  </svg>
);

export default StartIcon;
