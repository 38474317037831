import { useRef, useState } from 'react';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import HomeHeader from '../../components/Home/HomeHeader';
import HomeCOVID from '../../components/Home/HomeCOVID';
import HomeWhatSkills from '../../components/Home/HomeWhatSkills';
import HomeSteps from '../../components/Home/HomeSteps';

import GoDownIcon from '../../components/Icons/GoDownIcon';

import { useWindowResize } from '../../hooks';

import './style.scss';

const Home: React.FC = () => {
  const [welcomeBottom, setWelcomeBottom] = useState(0);
  const [stepsTop, setStepsTop] = useState(0);

  const welcomeRef = useRef<HTMLDivElement>(null);
  const stepsRef = useRef<HTMLDivElement>(null);

  useWindowResize(() => {
    const welcomeEl = welcomeRef.current;
    const stepsEl = stepsRef.current;

    if (welcomeEl) {
      setWelcomeBottom(
        welcomeEl.offsetTop +
          welcomeEl.getBoundingClientRect().height
      );
    }

    if (stepsEl) {
      setStepsTop(stepsEl.offsetTop);
    }
  });

  return (
    <Box className="home page">
      <HomeHeader
        fadeAt={welcomeBottom}
        hideAt={stepsTop}
      />

      <Container
        component="main"
        maxWidth="md"
        className="home-main"
        disableGutters
      >
        <Paper
          className="home-welcome"
          elevation={6}
          square
          sx={{ pt: { xs: '160px', sm: '232px' } }}
        >
          <Paper
            className="home-welcome-text"
            elevation={0}
            sx={{ background: '#fff' }}
            ref={welcomeRef}
          >
            <Typography variant="h6">
              ¡Bienvenidx a <b>BISION!</b>
            </Typography>
            <Typography variant="h6" sx={{ mt: 2 }}>
              Una plataforma para ayudarte a identificar
              fortalezas y oportunidades para tener un mayor
              bien & estar, después de identificarlas,
              podrás iniciar tu camino de desarrollo para{' '}
              <b>
                mejorar aquello que desees de manera
                incremental.
              </b>
            </Typography>
          </Paper>

          <Box
            className="home-cta-down"
            sx={{
              pt: { xs: 2, sm: 8 },
              pb: { xs: 2, sm: 8 },
            }}
          >
            <GoDownIcon />
            <Typography>
              Desliza hacia abajo
              <br />
              para comenzar.
            </Typography>
          </Box>
        </Paper>

        <HomeCOVID />

        <HomeWhatSkills />

        <HomeSteps stickedAt={stepsTop} ref={stepsRef} />
      </Container>
    </Box>
  );
};

export default Home;
