import { useEffect, useRef, useState } from 'react';

import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import { AnimatePresence, motion } from 'framer-motion';

import {
  RatingScaleQuestion,
  QuestionableScale,
} from '../../../types';
import { RatingScaleAnswer } from '../../../types/endpoints';

import { ratingScaleFormSchema } from '../../../schemas';

import SubmitButton from '../../Shared/SubmitButton';
import RatingScaleRadio from './RatingScaleRadio';
import { map } from '../../../utils';

export interface ScaleHelperProps {
  label: string;
  level: number;
  onClose: () => void;
}

export const ScaleHelper: React.FC<ScaleHelperProps> = ({
  label,
  level,
  onClose,
}) => {
  const amt = (level - 1) * 25;
  const timerRef = useRef<number | null>(null);

  useEffect(() => {
    if (timerRef.current !== null) {
      window.clearTimeout(timerRef.current);
    }

    timerRef.current = window.setTimeout(() => {
      onClose();
    }, 1100);
  }, [onClose, label]);

  return (
    <Box
      className="scale-helper"
      component={motion.div}
      initial={{ opacity: 0.15 }}
      animate={{
        opacity: 1.0,
        transition: { duration: 0.2, ease: 'easeIn' },
      }}
      exit={{
        opacity: 0.15,
        transition: {
          duration: 0.2,
          ease: 'easeOut',
        },
      }}
    >
      <Box
        className="scale-helper-indicator"
        component={motion.div}
        initial={{ width: 0, height: 0 }}
        animate={{
          width: `${amt}%`,
          height: `${amt}%`,
          transition: {
            duration: 0.2,
            ease: 'easeIn',
            delay: 0.15,
          },
        }}
        exit={{
          width: 0,
          height: 0,
          transition: { duration: 0.2, ease: 'easeOut' },
        }}
      />
      <Typography
        className="scale-helper-label"
        variant="h6"
      >
        {label}
      </Typography>
    </Box>
  );
};

export interface RatingScaleFormProps {
  question: RatingScaleQuestion;
  onSubmit: (answer: RatingScaleAnswer) => void;
  onSelection: (i: number, j: number) => void;
  submitting: boolean;
}

export interface RatingScaleFormInput {
  ratingScales: {
    scaleId: string;
    ratingScaleLabelId: string;
  }[];
}

const RatingScaleForm: React.FC<RatingScaleFormProps> = ({
  question,
  onSubmit,
  onSelection,
  submitting,
}) => {
  const questionable = question.questionable;

  const [selectedScale, setSelectedScale] = useState<{
    label: string;
    level: number;
  } | null>(null);
  const [scales] = useState<QuestionableScale[]>(
    question.questionable_subtype === 'InvertedScales'
      ? [...questionable.scales].reverse()
      : questionable.scales
  );

  const { formState, control, reset, handleSubmit } =
    useForm<RatingScaleFormInput>({
      mode: 'onChange',
      resolver: yupResolver(ratingScaleFormSchema),
    });

  const { fields } = useFieldArray({
    control,
    name: 'ratingScales',
  });

  const onSubmitForm: SubmitHandler<
    RatingScaleFormInput
  > = (data) => {
    onSubmit({
      answerableType: 'RatingScale',
      ratingScales: data.ratingScales,
    });
  };

  const { labels } = questionable;

  useEffect(() => {
    const ratingScales = questionable.labels.map(
      (label) => ({
        scaleId: '',
        ratingScaleLabelId: label.id,
      })
    );

    reset({
      ratingScales,
    });
    window.scrollTo(0, 0);
    setSelectedScale(null);
  }, [question, questionable, reset]);

  return (
    <Box
      className="questionable-form questionable-rating-form"
      component="form"
      onSubmit={handleSubmit(onSubmitForm)}
    >
      <AnimatePresence>
        {!!selectedScale && (
          <ScaleHelper
            key="scale-helper"
            label={selectedScale.label}
            level={
              question.questionable_subtype ===
              'InvertedScales'
                ? map(
                    selectedScale.level,
                    1,
                    scales.length,
                    scales.length,
                    1
                  )
                : selectedScale.level
            }
            onClose={() => setSelectedScale(null)}
          />
        )}
      </AnimatePresence>

      {fields.map((field, index) => (
        <Box className="rating-form-field" key={field.id}>
          <FormControl className="rating-form-control">
            <Controller
              name={`ratingScales.${index}.scaleId`}
              control={control}
              defaultValue={field.scaleId}
              render={({ field: controlledField }) => (
                <RadioGroup
                  className="rating-form-radio-group"
                  aria-labelledby={`${labels[index].id}-label`}
                  {...controlledField}
                  onChange={(evt) => {
                    const scaleIndex = scales.findIndex(
                      (s) =>
                        s.id === evt.currentTarget.value
                    );

                    const scale = scales[scaleIndex];
                    setSelectedScale({
                      label: scale.content.es,
                      level: scale.value,
                    });

                    onSelection(index, scaleIndex);
                    controlledField.onChange(evt);
                  }}
                >
                  {scales.map((scale, indexScale) => (
                    <FormControlLabel
                      className="rating-form-control-label"
                      key={scale.id}
                      value={scale.id}
                      control={
                        <RatingScaleRadio
                          level={indexScale}
                        />
                      }
                      label=""
                    />
                  ))}
                </RadioGroup>
              )}
            />
            <FormLabel
              className="rating-form-field-label"
              id={`${labels[index].id}-label`}
            >
              <Typography variant="h6">
                {labels[index].content.es}
              </Typography>
            </FormLabel>
          </FormControl>
        </Box>
      ))}

      <SubmitButton
        disabled={!formState.isValid}
        loading={submitting}
      />
    </Box>
  );
};

export default RatingScaleForm;
