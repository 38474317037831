import { EvaluationData, Questionnaire } from '../types';

export const selectEvaluationData = (
  questionnaire: Questionnaire,
  level1Id: string,
  level2Id: string = ''
): EvaluationData => {
  const level1 = questionnaire.level1.filter(
    (level1) => level1.id === level1Id
  )[0];

  const questions = level1.questions.filter((q) =>
    !level2Id ? true : q.level2.id === level2Id
  );

  const completed = questions.reduce(
    (prev, curr) => prev + Number(curr.answered),
    0
  );

  return {
    level1,
    questions: questions.filter((q) => !q.answered),
    progress: completed / questions.length,
  };
};
