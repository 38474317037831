import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Thermometer from '../Thermometer';
import WellnessCircle from '../WellnessCircle';
import PointerIcon from '../../Icons/PointerIcon';

import { Level1 } from '../../../types';

import './style.scss';

export interface DashboardCircleProps {
  levels: Level1[];
  showGraphs: boolean;
  onClick?: () => void;
}

const DashboardCircle: React.FC<DashboardCircleProps> = ({
  levels,
  showGraphs,
  onClick,
}) => {
  const navigate = useNavigate();

  const atLeastOneAnswer = !!levels.find(
    (level) =>
      !!level.questions.find(
        (question) => question.answered
      )
  );

  return (
    <Box className="dashboard-circle">
      <WellnessCircle
        levels={levels}
        showGraphs={showGraphs}
        onClick={onClick}
      />
      <Thermometer onClick={onClick} />

      {!atLeastOneAnswer && (
        <Box
          className="dashboard-first-time"
          onClick={() => {
            navigate('/dashboard/evaluations');
          }}
        >
          <PointerIcon />
          <Typography variant="h5">
            Toca aquí para comenzar.
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default DashboardCircle;
