import { motion, Variants } from 'framer-motion';

import CircularProgress from '@mui/material/CircularProgress';
import Fab from '@mui/material/Fab';
import Typography from '@mui/material/Typography';

const fabVariants: Variants = {
  disabled: { right: '-122px', bottom: '-30px' },
  enabled: { right: '-30px', bottom: '62px' },
};

const SubmitButton: React.FC<{
  disabled: boolean;
  loading: boolean;
}> = ({ disabled, loading }) => (
  <Fab
    component={motion.button}
    className="questionable-submit"
    disableFocusRipple
    disableRipple
    disableTouchRipple
    aria-label="submit"
    type="submit"
    disabled={disabled || loading}
    variants={fabVariants}
    initial={false}
    animate={disabled ? 'disabled' : 'enabled'}
    transition={{
      duration: 0.5,
      ease: 'easeOut',
    }}
  >
    {loading && <CircularProgress />}
    {!loading && (
      <Typography variant="h5">Enviar</Typography>
    )}
  </Fab>
);

export default SubmitButton;
