import { useState } from 'react';

import Box from '@mui/material/Box';
import Container from '@mui/system/Container';

import AppBar from '../AppBar';
import MenuNav from '../MenuNav';

const Layout: React.FC<{
  children: React.ReactNode;
  title?: string;
}> = ({ children, title }) => {
  const [openNav, setOpenNav] = useState(false);

  return (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <AppBar
        openNav={openNav}
        onOpenNav={() => {
          setOpenNav(true);
        }}
        onCloseNav={() => {
          setOpenNav(false);
        }}
        title={title}
        showProfile={false}
      />
      <MenuNav
        open={openNav}
        onClose={() => {
          setOpenNav(false);
        }}
      />
      <Container
        component="main"
        maxWidth="md"
        disableGutters
        sx={{
          flex: 1,
          paddingTop: '82px',
        }}
      >
        {children}
      </Container>
    </Box>
  );
};

export default Layout;
