import { useEffect, useRef, useState } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { AnimatePresence, useScroll } from 'framer-motion';

import { Level1, Question } from '../../../types';

import { ICON_LEVEL_MAP } from '../../../constants';

import ProgressCircle from '../../Shared/ProgressCircle';
import ProgressNumber from '../../Shared/ProgressNumber';
import QuestionableForm from '../QuestionableForm';
import MultipleOptionStepper from '../MultipleOptionStepper';
import RatingScaleStepper from '../RatingScaleStepper';
import CelebrateFinish from './CelebrateFinish';

import './style.scss';

interface QuestionDisplayProps {
  level1: Level1;
  question: Question;
  progress: number;
  onAnswered: () => void;
  lastQuestion: boolean;
}

const QuestionDisplay: React.FC<QuestionDisplayProps> = ({
  level1,
  question,
  progress,
  onAnswered,
  lastQuestion,
}) => {
  const [showCelebrate, setShowCelebrate] = useState(false);
  const [height, setHeight] = useState(0);
  const [selection, setSelection] = useState<boolean[][]>([
    [],
  ]);

  const headerRef = useRef<HTMLDivElement>(null);

  const { scrollYProgress } = useScroll();

  const Icon = ICON_LEVEL_MAP[level1.name];

  useEffect(() => {
    const onResize = () => {
      const header = headerRef.current;
      if (!header) return;

      const rect = header.getBoundingClientRect();
      setHeight(rect.height);
    };

    onResize();
    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [question]);

  useEffect(() => {
    if (question.questionable_type === 'MultipleOption') {
      setSelection([
        new Array(
          question.questionable.options.length
        ).fill(false),
      ]);
    }

    if (question.questionable_type === 'RatingScale') {
      setSelection(
        question.questionable.labels.map(() =>
          new Array(
            question.questionable.scales.length
          ).fill(false)
        )
      );
    }
  }, [question]);

  return (
    <Box className="question-display">
      <Box className="question-header" ref={headerRef}>
        {question.questionable_type ===
          'MultipleOption' && (
          <MultipleOptionStepper
            selection={selection[0]}
            scrollY={scrollYProgress}
          />
        )}
        {question.questionable_type === 'RatingScale' && (
          <RatingScaleStepper
            selection={selection}
            scrollY={scrollYProgress}
          />
        )}

        <Box className="question-summary">
          {Icon && <Icon />}
          <Typography
            className="question-level-title"
            variant="h6"
          >
            {question.level2.name.es}
          </Typography>
          <ProgressCircle progress={progress} />
          <ProgressNumber progress={progress} />
        </Box>

        <Box className="question-content">
          {question.questionable_type !== 'RatingScale' && (
            <Typography
              className="question-name"
              variant="h6"
            >
              {question.name.es}
            </Typography>
          )}

          <Typography className="question-instruction">
            {question.questionable_type === 'OpenText' &&
              'Escribe tu respuesta'}
            {question.questionable_type ===
              'MultipleOption' &&
              'Selecciona la opción con la que mejor te identifiques'}
            {question.questionable_type === 'RatingScale' &&
              'Selecciona el nivel con el que más te identifiques'}
          </Typography>
        </Box>
      </Box>

      <QuestionableForm
        level1={level1}
        question={question}
        paddingTop={height}
        onAnswered={() => {
          if (lastQuestion) {
            setShowCelebrate(true);
            return;
          }

          onAnswered();
        }}
        onSelection={(i, j) => {
          const newSelection = [...selection];
          newSelection[i] = [...selection[i]].fill(false);
          newSelection[i][j] = true;

          setSelection(newSelection);
        }}
      />

      <AnimatePresence>
        {showCelebrate && (
          <CelebrateFinish
            key="celebrate-finish"
            onClose={() => {
              setShowCelebrate(false);
              onAnswered();
            }}
          />
        )}
      </AnimatePresence>
    </Box>
  );
};

export default QuestionDisplay;
