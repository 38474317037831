const VulnerabilityIcon: React.FC = () => (
  <svg
    width="58"
    height="60"
    viewBox="0 0 58 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M56.3 45.4L23.42 37.03L1 51.48"
      stroke="#F29C00"
      strokeWidth="2.01"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeDasharray="3.62 7.24 3.62 7.24 3.62 7.24"
    />
    <path
      d="M27.6499 43.2699C27.9159 43.5337 28.2753 43.6817 28.6499 43.6817C29.0245 43.6817 29.3839 43.5337 29.6499 43.2699L44.1499 28.9799C45.6494 27.0755 46.537 24.7616 46.6959 22.3429C46.8547 19.9242 46.2774 17.5141 45.0399 15.4299C40.9299 8.14988 30.0899 10.0799 28.7699 18.1199C28.7625 18.1446 28.7473 18.1664 28.7266 18.1818C28.7059 18.1973 28.6807 18.2056 28.6549 18.2056C28.6291 18.2056 28.6039 18.1973 28.5832 18.1818C28.5625 18.1664 28.5473 18.1446 28.5399 18.1199C27.2299 10.1199 16.3899 8.11988 12.2699 15.4299C11.0324 17.5141 10.4551 19.9242 10.6139 22.3429C10.7728 24.7616 11.6604 27.0755 13.1599 28.9799L27.6499 43.2699Z"
      fill="#D34F7A"
      stroke="#8C1A3C"
      strokeWidth="3.29"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M1.4199 15.5399L32.2099 22.6999C32.2519 22.7152 32.2979 22.7152 32.3399 22.6999L55.8899 9.53992C55.922 9.52591 55.9494 9.50285 55.9686 9.47354C55.9878 9.44423 55.998 9.40996 55.998 9.37492C55.998 9.33989 55.9878 9.3056 55.9686 9.27629C55.9494 9.24698 55.922 9.22392 55.8899 9.20992L23.5299 0.96991C23.5079 0.95997 23.4841 0.954834 23.4599 0.954834C23.4358 0.954834 23.4119 0.95997 23.3899 0.96991L1.38991 15.1399C1.33686 15.1439 1.28758 15.1688 1.25288 15.2091C1.21819 15.2494 1.20093 15.3019 1.20491 15.3549C1.20889 15.408 1.23377 15.4572 1.27409 15.4919C1.31441 15.5266 1.36686 15.5439 1.4199 15.5399Z"
      stroke="#F29C00"
      strokeWidth="2.01"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M1 51.4799L32.29 58.7499L56.3 45.3999"
      stroke="#F29C00"
      strokeWidth="2.01"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M1 51.4699V15.4399"
      stroke="#F29C00"
      strokeWidth="2.01"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M7.78003 52.9699V16.9299"
      stroke="#F29C00"
      strokeWidth="2.01"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M22.4199 55.9801V21.1101"
      stroke="#F29C00"
      strokeWidth="2.01"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M24.3201 8.05997V6.21997"
      stroke="#F29C00"
      strokeWidth="2.01"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M32.3899 58.3601V23.8"
      stroke="#F29C00"
      strokeWidth="2.01"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M41.1499 53.12V18.23"
      stroke="#F29C00"
      strokeWidth="2.01"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M50.52 48.61V13.24"
      stroke="#F29C00"
      strokeWidth="2.01"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M56.3 45.4001V9.36011"
      stroke="#F29C00"
      strokeWidth="2.01"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);

export default VulnerabilityIcon;
