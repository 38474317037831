import { useEffect, useRef, useState } from 'react';

import Box from '@mui/system/Box';
import Typography from '@mui/material/Typography';
import {
  AnimatePresence,
  animate,
  motion,
  usePresence,
} from 'framer-motion';

import WellnessIcons from './WellnessIcons';

import questionnaireAPI from '../../../api/questionnaires';

import { BaseLevel1 } from '../../../types';

import {
  getSliceD,
  map,
  polarToCartesian,
} from '../../../utils';

import './style.scss';

const TWO_PI = Math.PI * 2.0;
const START_ANGLE = 0.3;
const START_ANGLE_LABELS = 0.3;
const START_RADIUS = 60;
const END_RADIUS = 144;

const LEVEL_COLORS = [
  '#D34F7A',
  '#FCCB83',
  '#7FCCEA',
  '#A82F58',
  '#4FB3E2',
  '#FCB542',
];

const LevelSliceGraph: React.FC<{
  level: BaseLevel1;
  index: number;
  start: number;
  end: number;
}> = ({ level, index, start, end }) => {
  const [isPresent, safeToRemove] = usePresence();

  const pathRef = useRef<SVGPathElement>(null);

  const finalRadius = map(
    level.score ?? 0,
    0,
    1,
    START_RADIUS,
    END_RADIUS
  );

  useEffect(() => {
    const updateRadius = (radius: number) => {
      pathRef.current?.setAttribute(
        'd',
        getSliceD(150, 150, radius, start, end)
      );
    };

    if (isPresent) {
      animate(START_RADIUS, finalRadius, {
        duration: 0.25,
        onUpdate: updateRadius,
      });
    }

    if (!isPresent) {
      if (!isPresent) {
        animate(finalRadius, START_RADIUS, {
          duration: 0.25,
          onUpdate: updateRadius,
          onComplete: safeToRemove,
        });
      }
    }
  }, [end, finalRadius, isPresent, safeToRemove, start]);

  return (
    <motion.path
      className="wellness-circle-slice-graph"
      ref={pathRef}
      style={{
        fill: LEVEL_COLORS[index % LEVEL_COLORS.length],
      }}
    />
  );
};

export interface WellnessCircleProps {
  levels: BaseLevel1[];
  showGraphs: boolean;
  onClick?: () => void;
}

const WellnessCircle: React.FC<WellnessCircleProps> = ({
  levels: sourceLevels,
  showGraphs,
  onClick,
}) => {
  const [levels, setLevels] = useState<BaseLevel1[]>([]);

  useEffect(() => {
    (async () => {
      const promises = sourceLevels.map(({ id }) =>
        questionnaireAPI.getLevelResults(id)
      );

      const results = await Promise.all(promises);
      setLevels(
        sourceLevels.map((level, i) => ({
          ...level,
          score:
            results[i].length > 0 ? results[i][0].score : 0,
        }))
      );
    })();
  }, [sourceLevels]);

  return (
    <Box className="wellness-circle-container">
      <svg
        className="wellness-circle"
        viewBox="0 0 300 300"
        fill="none"
      >
        <circle
          cx="150"
          cy="150"
          r="146"
          stroke="#2097A0"
          strokeWidth="6px"
          fill="white"
        />

        <AnimatePresence initial={false} exitBeforeEnter>
          {showGraphs &&
            levels.map((level, i) => (
              <LevelSliceGraph
                key={`slice-graph-{${level.id}`}
                level={level}
                index={i}
                start={
                  (TWO_PI / levels.length) * i + START_ANGLE
                }
                end={
                  (TWO_PI / levels.length) * (i + 1) +
                  START_ANGLE
                }
              />
            ))}

          {!showGraphs && <WellnessIcons />}
        </AnimatePresence>

        {levels.map((level, i) => (
          <path
            key={`slice-{${level.id}`}
            className="wellness-circle-slice"
            d={getSliceD(
              150,
              150,
              146,
              (TWO_PI / levels.length) * i + START_ANGLE,
              (TWO_PI / levels.length) * (i + 1) +
                START_ANGLE
            )}
          />
        ))}
      </svg>

      <Box
        className="wellness-circle-labels"
        onClick={onClick}
      >
        <AnimatePresence>
          {showGraphs &&
            levels.map((level, i) => {
              const start =
                (TWO_PI / levels.length) * (i + 0.5) +
                START_ANGLE_LABELS;
              const pos = polarToCartesian(
                50,
                50,
                34,
                start
              );

              return (
                <Typography
                  key={`wellness-circle-label-${level.id}`}
                  className="wellness-circle-label"
                  variant="caption"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{
                    duration: 0.15,
                    delay: 0.1,
                  }}
                  component={motion.span}
                  sx={{
                    left: `${pos.x}%`,
                    top: `${pos.y}%`,
                  }}
                >
                  {level.full_name.es}
                </Typography>
              );
            })}
        </AnimatePresence>
      </Box>
    </Box>
  );
};

export default WellnessCircle;
