import { motion } from 'framer-motion';

import { constrain } from '../../../utils';

const ProgressCircle: React.FC<{ progress: number }> = ({
  progress,
}) =>
  progress === 0 ? (
    <svg
      className="progress-circle"
      width="29"
      height="29"
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="14.5"
        cy="14.5"
        r="13"
        stroke="#999999"
        strokeOpacity="0.52"
        strokeWidth="3"
      />
    </svg>
  ) : (
    <svg
      className="progress-circle"
      width="29"
      height="29"
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <motion.path
        d="M14.5015 26.0017C20.8527 26.0017 26.0015 20.853 26.0015 14.5017C26.0015 8.15043 20.8527 3.00171 14.5015 3.00171C8.15019 3.00171 3.00146 8.15043 3.00146 14.5017C3.00146 20.853 8.15019 26.0017 14.5015 26.0017Z"
        stroke="#4FB3E2"
        strokeWidth="4.13"
        strokeLinecap="round"
        initial={{
          pathLength: 0,
        }}
        animate={{
          pathLength: constrain(progress * 3.0, 0, 1),
          transition: {
            duration: 0.3,
            ease: 'easeIn',
          },
        }}
      />
      <motion.path
        d="M4.47168 20.1316C2.97985 17.4723 2.60418 14.3296 3.42725 11.3935C4.25032 8.45749 6.20475 5.96802 8.86158 4.47165C11.521 2.97982 14.6636 2.60419 17.5996 3.42726C20.5357 4.25033 23.0254 6.20483 24.5217 8.86165"
        stroke="#8C1A3C"
        strokeWidth="4.13"
        strokeLinecap={
          constrain(progress * 3.0 - 1.0, 0, 1) > 0
            ? 'round'
            : 'butt'
        }
        initial={{
          pathLength: 0,
        }}
        animate={{
          pathLength: constrain(progress * 3.0 - 1.0, 0, 1),
          transition: {
            duration: 0.3,
            ease: 'easeIn',
          },
        }}
      />
      <motion.path
        d="M17.4717 20.4316C15.9385 21.2846 14.1294 21.4941 12.4419 21.0141C10.7543 20.5341 9.32637 19.4038 8.47168 17.8716C8.04624 17.1137 7.77467 16.2792 7.67261 15.416C7.57054 14.5529 7.64002 13.6781 7.87695 12.8418C8.11389 12.0056 8.51356 11.2244 9.05322 10.543C9.59289 9.86172 10.2619 9.29369 11.0217 8.87158"
        stroke="#F29C00"
        strokeWidth="2.37"
        strokeLinecap={
          constrain(progress * 3.0 - 2.0, 0, 1) > 0
            ? 'round'
            : 'butt'
        }
        initial={{
          pathLength: 0,
        }}
        animate={{
          pathLength: constrain(progress * 3.0 - 2.0, 0, 1),
          transition: {
            duration: 0.3,
            ease: 'easeIn',
          },
        }}
      />
    </svg>
  );

export default ProgressCircle;
