import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Paper from '@mui/material/Paper';
import { NavLink } from 'react-router-dom';

import BISIONIcon from '../../components/Icons/BISIONIcon';
import ChartIcon from '../../components/Icons/ChartIcon';

import './style.scss';

const IndexNav: React.FC = () => (
  <NavLink to="/dashboard" end>
    {({ isActive }) => <BISIONIcon inactive={!isActive} />}
  </NavLink>
);

const EvaluationsNav: React.FC = () => (
  <NavLink to="/dashboard/evaluations">
    {({ isActive }) => <ChartIcon inactive={!isActive} />}
  </NavLink>
);

const BottomNav: React.FC = () => (
  <Paper
    className="bottom-nav"
    elevation={4}
    square
    sx={{ zIndex: (theme) => theme.zIndex.drawer - 2 }}
  >
    <BottomNavigation sx={{ height: 86 }}>
      <BottomNavigationAction icon={<IndexNav />} />
      <BottomNavigationAction icon={<EvaluationsNav />} />
    </BottomNavigation>
  </Paper>
);

export default BottomNav;
