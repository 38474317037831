import { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import {
  motion,
  MotionValue,
  useTransform,
} from 'framer-motion';

import { arcPathD, constrain } from '../../../utils';

import './style.scss';

const PI = Math.PI;

const SCALE_COLORS = [
  '#FCCB83',
  '#FCB542',
  '#F29C00',
  '#DB8803',
  '#A54605',
];

const RatingScaleStepperLine: React.FC<{
  index: number;
  steps: number;
  scrollY: MotionValue;
}> = ({ index, steps, scrollY }) => {
  const [amt, setAmt] = useState(0);

  const amtMotion = useTransform(scrollY, (v) =>
    constrain(v * steps - index, 0, 1)
  );

  useEffect(() => {
    amtMotion.onChange((v) => setAmt(v));

    return () => {
      amtMotion.clearListeners();
    };
  }, [amtMotion]);

  return (
    <motion.path
      className="rating-stepper-line"
      d={arcPathD(
        16,
        14 + index * 28,
        15,
        PI * (1.5 - amt),
        PI * 1.5
      )}
    />
  );
};

export interface RatingScaleStepperProps {
  selection: boolean[][];
  scrollY: MotionValue;
}

const RatingScaleStepper: React.FC<
  RatingScaleStepperProps
> = ({ selection, scrollY }) => {
  const width = 28 * selection[0].length;
  const height = 28 * selection.length;

  return (
    <Paper
      className="rating-stepper"
      sx={{
        zIndex: '2',
        backgroundColor: '#202020',
        borderRadius: '0 0 10px 10px',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      }}
    >
      <Box
        className="rating-stepper-steps"
        sx={{ width: `${width}px`, height: `${height}px` }}
      >
        {selection.map((row, i) =>
          row.map((_, j) => (
            <motion.div
              key={`step-${i}-${j}`}
              className={`rating-stepper-step${
                selection[i][j] ? ' selected' : ''
              }`}
              style={{
                backgroundColor: SCALE_COLORS[j],
                left: 8 + j * 28,
                top: 8 + i * 28,
              }}
            />
          ))
        )}

        <svg
          className="rating-stepper-path"
          height={height + 2}
          viewBox={`0 0 30 ${height + 2}`}
        >
          {selection.map((_, i) => (
            <RatingScaleStepperLine
              key={`step-path-${i}`}
              index={i}
              steps={selection.length}
              scrollY={scrollY}
            />
          ))}
        </svg>
      </Box>
    </Paper>
  );
};

export default RatingScaleStepper;
