import { useState } from 'react';

import IconButton from '@mui/material/IconButton';

const ProfileButton: React.FC = () => {
  const [pressed, setPressed] = useState(false);

  return (
    <IconButton
      className="profile-button"
      onMouseDown={() => setPressed(true)}
      onTouchStart={() => setPressed(true)}
      onMouseUp={() => setPressed(false)}
      onTouchEnd={() => setPressed(false)}
    >
      <svg
        width="40"
        height="46"
        viewBox="0 0 40 46"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.28003 45.34V42.11C2.27872 39.8254 2.72755 37.563 3.60089 35.452C4.47424 33.341 5.75493 31.4228 7.36988 29.8069C8.98482 28.191 10.9023 26.9092 13.0128 26.0347C15.1233 25.1601 17.3855 24.71 19.67 24.71V24.71C24.2848 24.71 28.7106 26.5432 31.9737 29.8063C35.2368 33.0694 37.07 37.4952 37.07 42.11V45.34"
          stroke="#4FB3E2"
          strokeWidth="4.55"
          strokeMiterlimit="10"
        />
        <path
          d="M19.67 23.69C25.5849 23.69 30.38 18.895 30.38 12.98C30.38 7.06505 25.5849 2.27002 19.67 2.27002C13.755 2.27002 8.95996 7.06505 8.95996 12.98C8.95996 18.895 13.755 23.69 19.67 23.69Z"
          stroke="#4FB3E2"
          strokeWidth="4.55"
          strokeMiterlimit="10"
        />
        <path
          d="M25.52 13.0699C25.5088 13.8381 25.3464 14.5966 25.0421 15.3021C24.7378 16.0076 24.2975 16.6463 23.7464 17.1816C23.1953 17.7169 22.5442 18.1384 21.8301 18.4221C21.1161 18.7058 20.3532 18.8461 19.585 18.8349C18.8167 18.8237 18.0582 18.6614 17.3527 18.3571C16.6472 18.0528 16.0086 17.6125 15.4733 17.0614C14.9379 16.5103 14.5164 15.8591 14.2327 15.1451C13.9491 14.431 13.8088 13.6681 13.82 12.8999"
          stroke="#4FB3E2"
          strokeWidth="2.49"
          strokeMiterlimit="10"
        />
        {pressed && (
          <path
            d="M12.5 13H19.75H26.5"
            stroke="#4FB3E2"
            strokeWidth="2"
          />
        )}
      </svg>
    </IconButton>
  );
};

export default ProfileButton;
