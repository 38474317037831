const ReturnIcon: React.FC = () => (
  <svg
    width="69"
    height="37"
    viewBox="0 0 69 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.455 18.1601C10.455 15.3158 8.1493 13.0101 5.30503 13.0101C2.46076 13.0101 0.155029 15.3158 0.155029 18.1601C0.155029 21.0043 2.46076 23.3101 5.30503 23.3101C8.1493 23.3101 10.455 21.0043 10.455 18.1601Z"
      fill="white"
    />
    <path
      d="M15.595 1.01007C25.065 1.01007 32.745 18.1601 32.745 18.1601C32.745 18.1601 25.065 35.3101 15.595 35.3101"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M32.7451 1.01007C42.2251 1.01007 49.9051 18.1601 49.9051 18.1601C49.9051 18.1601 42.2251 35.3101 32.7451 35.3101"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M49.905 1.01007C59.375 1.01007 67.0551 18.1601 67.0551 18.1601C67.0551 18.1601 59.375 35.3101 49.905 35.3101"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);

export default ReturnIcon;
