import { useEffect } from 'react';

import {
  Controller,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Slider from '@mui/material/Slider';
import TextField from '@mui/material/TextField';

import { OpenTextAnswer } from '../../../types/endpoints';

import SubmitButton from '../../Shared/SubmitButton';

import {
  openTextFormSchema,
  openTextNumberFormSchema,
} from '../../../schemas';

import { Question } from '../../../types';

export interface OpenTextFormProps {
  question: Question;
  onSubmit: (answer: OpenTextAnswer) => void;
  submitting: boolean;
}

export interface OpenTextFormInput {
  value: string;
}

const OpenTextForm: React.FC<OpenTextFormProps> = ({
  question,
  onSubmit,
  submitting,
}) => {
  const { handleSubmit, control, formState, reset } =
    useForm<OpenTextFormInput>({
      mode: 'onChange',
      defaultValues: {
        value: '',
      },
      resolver:
        question.content_type === 'number'
          ? yupResolver(openTextNumberFormSchema)
          : yupResolver(openTextFormSchema),
    });

  const onSubmitForm: SubmitHandler<OpenTextFormInput> = (
    data
  ) => {
    onSubmit({
      answerableType: 'OpenText',
      text: data.value,
    });
  };

  useEffect(() => {
    reset();
    window.scrollTo(0, 0);
  }, [reset, question.id]);

  return (
    <Box
      className="questionable-form questionable-open-text-form"
      component="form"
      onSubmit={handleSubmit(onSubmitForm)}
    >
      <FormControl>
        <Controller
          name="value"
          control={control}
          render={({ field }) =>
            question.name.es === 'Edad' ? (
              <Slider
                {...field}
                className="open-text-slider"
                aria-label="Edad"
                defaultValue={18}
                valueLabelDisplay="on"
                step={1}
                marks={[
                  {
                    value: 18,
                    label: '18',
                  },
                  {
                    value: 90,
                    label: '90',
                  },
                ]}
                min={18}
                max={90}
                value={
                  isNaN(parseInt(field.value))
                    ? 18
                    : parseInt(field.value)
                }
              />
            ) : (
              <TextField
                {...field}
                className="open-text-field"
                variant="outlined"
                placeholder="Escribe tu respuesta..."
                multiline={
                  question.content_type !== 'number'
                }
                rows={
                  question.content_type !== 'number' ? 4 : 1
                }
                type={
                  question.content_type !== 'number'
                    ? 'text'
                    : 'number'
                }
              />
            )
          }
        />
      </FormControl>

      <SubmitButton
        disabled={!formState.isValid}
        loading={submitting}
      />
    </Box>
  );
};

export default OpenTextForm;
