export interface BISIONIconProps {
  inactive?: boolean;
}

const BISIONIcon: React.FC<BISIONIconProps> = ({
  inactive = false,
}) =>
  !inactive ? (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.43 40.0199C32.4705 40.0199 40.61 31.8804 40.61 21.8399C40.61 11.7994 32.4705 3.65991 22.43 3.65991C12.3895 3.65991 4.25 11.7994 4.25 21.8399C4.25 31.8804 12.3895 40.0199 22.43 40.0199Z"
        stroke="#4FB3E2"
        strokeWidth="6.53"
        strokeMiterlimit="10"
      />
      <path
        d="M6.57003 30.7398C4.20828 26.5361 3.61317 21.5665 4.91561 16.924C6.21805 12.2816 9.31136 8.34658 13.515 5.98483C17.7187 3.62307 22.6884 3.02797 27.3309 4.33041C31.9734 5.63285 35.9083 8.72621 38.27 12.9299"
        stroke="#8C1A3C"
        strokeWidth="6.53"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M27.13 31.21C24.7139 32.5067 21.8849 32.8018 19.2531 32.0316C16.6214 31.2614 14.3978 29.4877 13.062 27.0929C11.7262 24.6981 11.3851 21.8744 12.1125 19.2305C12.8398 16.5866 14.5773 14.3345 16.9501 12.96"
        stroke="#F29C00"
        strokeWidth="3.74"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  ) : (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.43 40.0199C32.4705 40.0199 40.61 31.8804 40.61 21.8399C40.61 11.7994 32.4705 3.65991 22.43 3.65991C12.3895 3.65991 4.25 11.7994 4.25 21.8399C4.25 31.8804 12.3895 40.0199 22.43 40.0199Z"
        stroke="#A3A3A3"
        strokeWidth="6.53"
        strokeMiterlimit="10"
      />
      <path
        d="M6.57007 30.7501C4.29831 26.5528 3.76819 21.6304 5.09418 17.0456C6.42016 12.4608 9.49588 8.58133 13.6574 6.24459C17.8189 3.90785 22.7324 3.30133 27.3372 4.55587C31.9421 5.81041 35.8689 8.82537 38.2701 12.95"
        stroke="#7C7976"
        strokeWidth="6.53"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M27.1299 31.21C24.7085 32.5599 21.85 32.8927 19.1832 32.135C16.5164 31.3774 14.2599 29.5915 12.9099 27.17C11.56 24.7486 11.2272 21.89 11.9849 19.2233C12.7425 16.5565 14.5285 14.2999 16.9499 12.95"
        stroke="#D1D0CF"
        strokeWidth="3.74"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  );

export default BISIONIcon;
