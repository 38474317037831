const GoDownIcon: React.FC = () => (
  <svg
    width="37"
    height="68"
    viewBox="0 0 37 68"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.15 10.3C20.9943 10.3 23.3 7.99427 23.3 5.15C23.3 2.30573 20.9943 0 18.15 0C15.3057 0 13 2.30573 13 5.15C13 7.99427 15.3057 10.3 18.15 10.3Z"
      fill="white"
    />
    <path
      d="M35.3 15.4399C35.3 24.9099 18.15 32.59 18.15 32.59C18.15 32.59 1 24.9099 1 15.4399"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M35.3 32.5901C35.3 42.0701 18.15 49.7501 18.15 49.7501C18.15 49.7501 1 42.0701 1 32.5901"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M35.3 49.75C35.3 59.22 18.15 66.9 18.15 66.9C18.15 66.9 1 59.22 1 49.75"
      stroke="white"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);

export default GoDownIcon;
