import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import COVIDImg from './COVIDImg';
import EmotionsImg from './EmotionsImg';
import MoneyImg from './MoneyImg';

import './style.scss';

const HomeCOVID: React.FC = () => (
  <Box className="home-covid">
    <Typography
      variant="h5"
      className="home-covid-txt-a"
      sx={{ mt: '4vh' }}
    >
      En BISION nos hemos preguntado
    </Typography>
    <Typography
      variant="h5"
      className="home-covid-txt-a"
      sx={{ mt: '1vh' }}
    >
      ¿Y si la próxima crisis mundial es una pandemia de
      salud mental?
    </Typography>
    <Box sx={{ width: '84%', mt: '3vh' }}>
      <COVIDImg />
    </Box>
    <Typography
      className="home-covid-txt-b"
      variant="h6"
      sx={{ mt: '3vh' }}
    >
      Las emociones negativas como la preocupación, estrés,
      ira y tristeza en el ámbito laboral de todo el mundo
      alcanzaron niveles récord en 2020.
    </Typography>
    <Box sx={{ mt: '6vh' }}>
      <EmotionsImg />
    </Box>
    <Typography
      className="home-covid-txt-b"
      variant="h6"
      sx={{ mt: '3vh' }}
    >
      Aproximadamente <b>siete de cada diez personas</b>{' '}
      están luchando o sufriendo, en lugar de prosperar, en
      sus vidas en general.
    </Typography>
    <Box className="home-covid-work" sx={{ mt: '6vh' }}>
      <Box sx={{ width: '34%' }}>
        <MoneyImg />
      </Box>
      <Box sx={{ flex: 1 }}>
        <Typography
          className="home-covid-txt-b home-covid-work-txt"
          variant="h6"
        >
          El <b>80%</b> no tiene sentido de pertenencia en
          su trabajo. Y esto impacta a la economía mundial
          en más de <b>8,000 millones de dólares</b>, casi
          el <b>10% del PIB</b> mundial.
        </Typography>
      </Box>
    </Box>
    <Typography
      className="home-covid-txt-b"
      variant="h6"
      sx={{ mt: '2vh' }}
    >
      Y las tendencias nos señalan que esto continuará...
    </Typography>
    <Typography
      className="home-covid-txt-c"
      sx={{ mt: '2vh', mb: '4vh' }}
    >
      Fuente: Gallup State of the Global Workplace 2021
    </Typography>
  </Box>
);

export default HomeCOVID;
