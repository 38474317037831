import { ReactElement, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '../../../hooks';

const RequireAuth: React.FC<{
  children: ReactElement;
}> = ({ children }) => {
  const [loading, setLoading] = useState(true);

  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const checked = auth.checkAuth();
    if (!checked) {
      navigate('/login', { replace: true });
    }

    setLoading(false);
  }, [auth, navigate]);

  if (loading) {
    return null;
  }

  return children;
};

export default RequireAuth;
