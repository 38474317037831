import { useContext } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import LevelCard from '../../components/Evaluations/LevelCard';
import RequireProfileCompleted from '../../components/Shared/RequireProfileCompleted';

import { QuestionnaireContext } from '../../providers/QuestionnaireProvider';

import './style.scss';
import { Link } from 'react-router-dom';

const Evaluations: React.FC = () => {
  const { questionnaire } = useContext(
    QuestionnaireContext
  );

  if (!questionnaire) {
    return null;
  }

  const levels = questionnaire.level1.filter(
    (level) => level.name !== 'Perfil'
  );

  const allCompleted = !levels.find(
    (level) => !level.completed
  );

  return (
    <RequireProfileCompleted>
      <Box className="evaluations">
        {allCompleted && (
          <Box className="evaluations-completed">
            <Typography
              variant="h6"
              className="evaluations-completed-text"
            >
              Gracias por completar BISION, el envío del análisis preliminar se enviará al correo con que te registraste en un periodo de 2 días hábiles.{' '}
            </Typography>
          </Box>
        )}

        <Typography
          className="evaluations-title"
          variant="h5"
          sx={{
            mt: allCompleted ? 0 : '5vh',
          }}
        >
          AUTODIAGNÓSTICOS
        </Typography>

        <Box className="evaluations-list">
          {levels.map((level) => (
            <LevelCard
              key={level.id}
              level={level}
              showResults={allCompleted}
            />
          ))}
        </Box>
      </Box>
    </RequireProfileCompleted>
  );
};

export default Evaluations;
