import { useEffect } from 'react';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import Layout from '../../layout/Layout';

import BISIONLogo from '../../components/Misc/BISIONLogo';

import Tutorial1 from '../../assets/tutorial-1-2.gif';
import Tutorial2 from '../../assets/tutorial-2.gif';
import Tutorial3 from '../../assets/tutorial-3.gif';
import Tutorial4 from '../../assets/tutorial-4.gif';
import Tutorial5 from '../../assets/tutorial-5.gif';
import Tutorial6 from '../../assets/tutorial-6.gif';
import Tutorial7 from '../../assets/tutorial-7.gif';
import Tutorial8 from '../../assets/tutorial-8.gif';

import './style.scss';

const HowTo: React.FC = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  return (
    <Layout title="Cómo utilizar BISION">
      <Box className="how-to">
        <Box className="how-to-section">
          <Box className="how-to-logo">
            <BISIONLogo />
          </Box>

          <Typography
            className="how-to-subtitle"
            variant="h5"
            sx={{
              marginBottom: '8vh',
            }}
          >
            En esta sección te daremos los pasos para
            empezar a utilizar BISION y explorar cada área
            desde tu perspectiva.
          </Typography>

          <Divider />
        </Box>

        <Box className="how-to-section">
          <Typography className="how-to-title" variant="h4">
            Paso 1
          </Typography>
          <Typography
            className="how-to-subtitle"
            variant="h5"
          >
            Inicia con inspiración.
          </Typography>
          <Box className="how-to-video">
            <img src={Tutorial1} alt="Paso de tutorial" />
          </Box>
          <Typography
            className="how-to-description"
            variant="h5"
          >
            Lee tu frase del día en la pantalla principal
            =). También puedes pulsar en la frase para
            ampliarla.
          </Typography>
          <Divider />
        </Box>

        <Box className="how-to-section">
          <Typography className="how-to-title" variant="h4">
            Paso 2
          </Typography>
          <Typography
            className="how-to-subtitle"
            variant="h5"
          >
            Empezar.
          </Typography>
          <Box className="how-to-video">
            <img src={Tutorial2} alt="Paso de tutorial" />
          </Box>
          <Typography
            className="how-to-description"
            variant="h5"
          >
            Pulsa este botón para mostrar las áreas que
            integran esta autoevaluación.
          </Typography>
          <Divider />
        </Box>

        <Box className="how-to-section">
          <Typography className="how-to-title" variant="h4">
            Paso 3
          </Typography>
          <Typography
            className="how-to-subtitle"
            variant="h5"
          >
            Áreas a evaluar.
          </Typography>
          <Box className="how-to-video">
            <img src={Tutorial3} alt="Paso de tutorial" />
          </Box>
          <Typography
            className="how-to-description"
            variant="h5"
          >
            Esta autoevaluación cuenta con 5 áreas y para
            cada una puedes ver el porcentaje de avance.
          </Typography>
          <Divider />
        </Box>

        <Box className="how-to-section">
          <Typography className="how-to-title" variant="h4">
            Paso 4
          </Typography>
          <Typography
            className="how-to-subtitle"
            variant="h5"
          >
            Secciones a evaluar.
          </Typography>
          <Box className="how-to-video">
            <img src={Tutorial4} alt="Paso de tutorial" />
          </Box>
          <Typography
            className="how-to-description"
            variant="h5"
          >
            Despliega cada área al pulsar la flecha para
            mostrar las secciones que integran cada una.
          </Typography>
          <Divider />
        </Box>

        <Box className="how-to-section">
          <Typography className="how-to-title" variant="h4">
            Paso 5
          </Typography>
          <Typography
            className="how-to-subtitle"
            variant="h5"
          >
            Momento de comenzar.
          </Typography>
          <Box className="how-to-video">
            <img src={Tutorial5} alt="Paso de tutorial" />
          </Box>
          <Typography
            className="how-to-description"
            variant="h5"
          >
            Pulsa el botón « Comenzar &gt; » que se
            encuentra a la derecha de cada sección. Puedes
            iniciar por cualquiera que te inspire y volver a
            ella si aún no ha sido finalizada.
          </Typography>
          <Divider />
        </Box>

        <Box className="how-to-section">
          <Typography className="how-to-title" variant="h4">
            Paso 6
          </Typography>
          <Typography
            className="how-to-subtitle"
            variant="h5"
          >
            Continúa.
          </Typography>
          <Box className="how-to-video">
            <img src={Tutorial6} alt="Paso de tutorial" />
          </Box>
          <Typography
            className="how-to-description"
            variant="h5"
          >
            Si alguna sección no ha sido finalizada puedes
            avanzar al pulsar el botón « Continuar &gt; »
            que se encuentra a la derecha de cada sección.
          </Typography>
          <Divider />
        </Box>

        <Box className="how-to-section">
          <Typography className="how-to-title" variant="h4">
            Paso 7
          </Typography>
          <Typography
            className="how-to-subtitle"
            variant="h5"
          >
            Descubre tu resultado.
          </Typography>
          <Box className="how-to-video">
            <img src={Tutorial7} alt="Paso de tutorial" />
          </Box>
          <Typography
            className="how-to-description"
            variant="h5"
          >
            Una vez completadas todas las áreas. Pulsa el
            botón del círculo de « bien & estar » para
            regresar al inicio.
          </Typography>
          <Divider />
        </Box>

        <Box className="how-to-section">
          <Typography className="how-to-title" variant="h4">
            Paso 8
          </Typography>
          <Typography
            className="how-to-subtitle"
            variant="h5"
          >
            Descubre tu resultado.
          </Typography>
          <Box className="how-to-video">
            <img src={Tutorial8} alt="Paso de tutorial" />
          </Box>
          <Typography
            className="how-to-description"
            variant="h5"
          >
            Pulsa el interruptor para visualizar tu
            resultado en cada área, los colores representan
            la ponderación de tus resultados, el espacio a
            color muestra que tan cerca estás de completar
            el círculo de tu bien & estar.
          </Typography>
        </Box>
      </Box>
    </Layout>
  );
};

export default HowTo;
