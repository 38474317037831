const BISIONLogo: React.FC = () => (
  <svg
    className="bision-logo"
    width="93"
    height="93"
    viewBox="0 0 93 93"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M46.05 85.09C67.6112 85.09 85.09 67.6112 85.09 46.05C85.09 24.4888 67.6112 7.01001 46.05 7.01001C24.4888 7.01001 7.01001 24.4888 7.01001 46.05C7.01001 67.6112 24.4888 85.09 46.05 85.09Z"
      stroke="#4FB3E2"
      strokeWidth="14.01"
      strokeMiterlimit="10"
    />
    <path
      d="M12.01 65.17C6.93908 56.142 5.66217 45.4694 8.46021 35.5C11.2583 25.5305 17.902 17.081 26.93 12.01C35.958 6.93908 46.6306 5.66217 56.6001 8.46021C66.5695 11.2583 75.0191 17.902 80.09 26.93"
      stroke="#8C1A3C"
      strokeWidth="14.01"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M56.16 66.18C53.5861 67.6153 50.7547 68.5296 47.8274 68.8707C44.9001 69.2118 41.9343 68.973 39.0994 68.168C36.2644 67.3629 33.6158 66.0073 31.3047 64.1786C28.9936 62.3499 27.0653 60.0839 25.63 57.51C24.1946 54.9361 23.2803 52.1046 22.9392 49.1774C22.5981 46.2501 22.8369 43.2843 23.642 40.4494C24.4471 37.6144 25.8027 34.9657 27.6314 32.6547C29.4601 30.3436 31.7261 28.4153 34.3 26.98"
      stroke="#F29C00"
      strokeWidth="8.04"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);

export default BISIONLogo;
