import axios from 'axios';

import { GetQuoteResponse } from '../types/endpoints';
import { API_URL } from './urls';

const get = async (): Promise<GetQuoteResponse> =>
  axios.get(`${API_URL}/api/quote/daily`);

const quotesAPI = { get };

export default quotesAPI;
