import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { AnimatePresence, motion } from 'framer-motion';
import { Link } from 'react-router-dom';

import ReturnIcon from '../../components/Icons/ReturnIcon';

import './style.scss';

export interface MenuNavProps {
  open: boolean;
  onClose: () => void;
}

interface MenuNavItem {
  text: string;
  color?: string;
  href: string;
}

const menuItems: Array<MenuNavItem> = [
  { text: 'Inicio', href: '/dashboard' },
  { text: 'Introducción', href: '/' },
  {
    text: '¿Cómo utilizar BISION?',
    color: '#4FB3E2',
    href: '/how-to',
  },
  {
    text: 'Acerca de BISION',
    color: '#F29C00',
    href: '/about',
  },
];

const drawerWidth = 240;

const MenuNav: React.FC<MenuNavProps> = ({
  open,
  onClose,
}) => (
  <Box component="nav">
    <Drawer
      variant="temporary"
      open={open}
      onClose={onClose}
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: '100%',
          boxSizing: 'border-box',
        },
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      PaperProps={{
        sx: {
          background: '#373737',
        },
      }}
      transitionDuration={200}
    >
      <Toolbar
        sx={{
          height: '22%',
          maxHeight: { xs: 100, sm: 140 },
        }}
      />
      <Box
        onClick={onClose}
        sx={{ width: '90%', overflow: 'hidden' }}
      >
        <List dense>
          <AnimatePresence>
            {menuItems.map((item, index) => (
              <ListItem
                key={`menu-nav-item-${index}`}
                disablePadding
                component={motion.li}
                initial={{ x: ' 120%' }}
                animate={{ x: ' 0%' }}
                transition={{
                  duration: 0.25,
                  delay: index * 0.05 + 0.1,
                  ease: [0.445, 0.05, 0.55, 0.95],
                }}
              >
                <ListItemButton
                  component={Link}
                  to={item.href}
                >
                  <ListItemText
                    primary={item.text}
                    primaryTypographyProps={{
                      variant: 'h6',
                    }}
                    sx={{
                      color: item.color ?? '#FFF',
                    }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </AnimatePresence>
        </List>
      </Box>
      <Box
        className="return-btn-container"
        sx={{ bottom: { xs: 10, sm: 50 } }}
      >
        <Button className="return-btn" onClick={onClose}>
          <ReturnIcon />
          <Typography sx={{ textTransform: 'none', mt: 2 }}>
            Regresar
          </Typography>
        </Button>
      </Box>
    </Drawer>
  </Box>
);

export default MenuNav;
