export interface ChartIconProps {
  inactive?: boolean;
}

const ChartIcon: React.FC<ChartIconProps> = ({
  inactive = false,
}) =>
  !inactive ? (
    <svg
      width="53"
      height="58"
      viewBox="0 0 53 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M40.04 31.8999C40.0424 34.8775 39.3472 37.8142 38.01 40.4746C36.6728 43.135 34.731 45.4452 32.3401 47.2198L25.5901 38.1299C26.5646 37.4097 27.3564 36.4708 27.9018 35.3887C28.4472 34.3066 28.7309 33.1117 28.73 31.8999H40.04Z"
        fill="#D34F7A"
        stroke="#8C1A3C"
        strokeWidth="3.56"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M40.0399 31.9001H28.6899C28.6847 29.842 27.864 27.8697 26.4077 26.4153C24.9514 24.9609 22.9781 24.1428 20.9199 24.1401V12.8301C22.532 12.8299 24.1379 13.0315 25.7 13.4302C29.7895 14.4944 33.4106 16.8846 35.9965 20.2268C38.5823 23.569 39.9867 27.6744 39.9899 31.9001H40.0399Z"
        fill="#FCCB83"
        stroke="#F29C00"
        strokeWidth="3.56"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M20.99 22.21L28.46 14.74"
        stroke="#F29C00"
        strokeWidth="3.56"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M32.34 47.2201C29.9165 49.0225 27.0996 50.2239 24.1213 50.7253C21.1429 51.2268 18.088 51.014 15.2079 50.1045C12.3279 49.195 9.70485 47.6148 7.55462 45.4938C5.40439 43.3728 3.78838 40.7715 2.83953 37.9042C1.89069 35.0368 1.63611 31.9852 2.09674 29.0002C2.55736 26.0153 3.72003 23.1823 5.4891 20.7344C7.25818 18.2864 9.58312 16.2935 12.2727 14.9193C14.9623 13.5452 17.9397 12.8291 20.96 12.8301V24.1401C19.7292 24.138 18.5156 24.4283 17.419 24.9871C16.3224 25.5458 15.3742 26.3572 14.6526 27.3541C13.9309 28.3511 13.4564 29.5052 13.2681 30.7214C13.0798 31.9377 13.1832 33.1814 13.5696 34.3499C13.9561 35.5184 14.6145 36.5783 15.4908 37.4425C16.3671 38.3067 17.4362 38.9503 18.61 39.3204C19.7838 39.6906 21.0287 39.7765 22.2422 39.5713C23.4557 39.3661 24.6031 38.8756 25.59 38.1401L32.34 47.2201Z"
        fill="#7FCCEA"
        stroke="#4FB3E2"
        strokeWidth="3.56"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M32.3401 47.22L36.3301 52.6H42.8901"
        stroke="#7C7976"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M26.3301 26.3001L33.8001 18.8301"
        stroke="#F29C00"
        strokeWidth="3.56"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M30.1001 32.0001L38.2501 23.8501"
        stroke="#F29C00"
        strokeWidth="3.56"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M45.5801 33.8201H51.3901"
        stroke="#7C7976"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M45.5801 37.6599H48.1901"
        stroke="#7C7976"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M37.22 56.8201H40.83"
        stroke="#7C7976"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M25.75 13.43L37.54 4.45996H44.11"
        stroke="#7C7976"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M25.7401 13.4299L25.5901 13.5499"
        stroke="#7C7976"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M38.4299 1H42.0499"
        stroke="#7C7976"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M28.6899 31.9C28.6945 33.6935 28.0793 35.4335 26.9484 36.8255C25.8175 38.2176 24.2404 39.1761 22.484 39.539C20.7276 39.9019 18.8997 39.6468 17.3097 38.8169C15.7198 37.9869 14.4654 36.6332 13.7589 34.9847C13.0524 33.3362 12.9372 31.4942 13.4327 29.7705C13.9282 28.0468 15.0041 26.5472 16.4782 25.5256C17.9523 24.504 19.734 24.0229 21.522 24.164C23.31 24.3051 24.9943 25.0598 26.2898 26.3C27.0479 27.0225 27.6515 27.8913 28.0641 28.8538C28.4766 29.8164 28.6895 30.8527 28.6899 31.9Z"
        fill="#F7F7F7"
        stroke="#7C7976"
        strokeWidth="3.56"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  ) : (
    <svg
      width="53"
      height="55"
      viewBox="0 0 53 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M40.1664 28.9001C40.1688 31.8777 39.4735 34.8144 38.1364 37.4749C36.7992 40.1353 34.8574 42.4455 32.4664 44.2202L25.7164 35.1602C27.031 34.1884 28.005 32.8263 28.4996 31.2682C28.9943 29.7101 28.9842 28.0357 28.4709 26.4836C27.9577 24.9316 26.9674 23.5812 25.6413 22.6252C24.3152 21.6693 22.7211 21.1566 21.0864 21.1602L30.0864 12.1602C33.1202 13.7818 35.6589 16.194 37.4334 19.141C39.208 22.088 40.1523 25.4601 40.1664 28.9001Z"
        fill="#F7F7F7"
        stroke="#7C7976"
        strokeWidth="3.56"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M32.4664 44.2201C30.0429 46.0225 27.2261 47.2238 24.2477 47.7252C21.2694 48.2267 18.2145 48.0139 15.3344 47.1044C12.4543 46.1949 9.83132 44.6146 7.68109 42.4937C5.53085 40.3727 3.91484 37.7715 2.966 34.9042C2.01716 32.0368 1.76258 28.9851 2.2232 26.0001C2.68382 23.0152 3.84649 20.1822 5.61557 17.7343C7.38464 15.2863 9.70958 13.2933 12.3992 11.9192C15.0887 10.545 18.0661 9.82906 21.0864 9.83008C22.6985 9.82987 24.3043 10.0314 25.8664 10.4301C27.3448 10.8146 28.7717 11.3752 30.1164 12.1L21.1164 21.1C19.8856 21.0979 18.672 21.3882 17.5754 21.947C16.4789 22.5058 15.5307 23.317 14.809 24.314C14.0874 25.3109 13.6129 26.4651 13.4246 27.6814C13.2363 28.8977 13.3396 30.1412 13.7261 31.3097C14.1125 32.4782 14.771 33.5382 15.6473 34.4023C16.5236 35.2665 17.5927 35.9103 18.7665 36.2804C19.9402 36.6505 21.1852 36.7365 22.3987 36.5313C23.6122 36.326 24.7596 35.8355 25.7464 35.1L32.4664 44.2201Z"
        fill="#F7F7F7"
        stroke="#7C7976"
        strokeWidth="3.56"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M28.8164 28.9001C28.821 30.6936 28.2059 32.4336 27.075 33.8256C25.9441 35.2177 24.367 36.1763 22.6106 36.5391C20.8541 36.902 19.0263 36.6469 17.4363 35.817C15.8464 34.987 14.592 33.6333 13.8855 31.9848C13.179 30.3363 13.0638 28.4943 13.5593 26.7706C14.0548 25.0469 15.1307 23.5474 16.6048 22.5257C18.0789 21.5041 19.8606 21.0231 21.6486 21.1643C23.4366 21.3054 25.1208 22.0599 26.4164 23.3001C27.1745 24.0226 27.7781 24.8915 28.1906 25.8541C28.6032 26.8167 28.8161 27.8529 28.8164 28.9001Z"
        fill="#F7F7F7"
        stroke="#7C7976"
        strokeWidth="3.56"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M21.0864 21.1399V9.85986"
        stroke="#7C7976"
        strokeWidth="2.52"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M28.8564 28.9102H40.1464"
        stroke="#7C7976"
        strokeWidth="2.52"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M32.4664 44.2202L36.4564 49.6002H43.0164"
        stroke="#7C7976"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M30.1665 12.0601L30.1265 12.1001"
        stroke="#7C7976"
        strokeWidth="2.52"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M26.4564 23.3001L33.9264 15.8301"
        stroke="#7C7976"
        strokeWidth="2.52"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M29.5465 27.7501L37.6965 19.6001"
        stroke="#7C7976"
        strokeWidth="2.52"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M45.7064 30.8198H51.5164"
        stroke="#7C7976"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M45.7064 34.6602H48.3164"
        stroke="#7C7976"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M37.3464 53.8198H40.9564"
        stroke="#7C7976"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M25.8765 10.43L37.6665 1.45996H44.2365"
        stroke="#7C7976"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M25.8664 10.4302L25.7164 10.5502"
        stroke="#7C7976"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M38.5564 8H42.1764"
        stroke="#7C7976"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  );

export default ChartIcon;
