import { createContext, useEffect, useState } from 'react';

import questionnairesApi from '../../api/questionnaires';

import { QUESTIONNAIRE_ID } from '../../constants';

import { Questionnaire } from '../../types';

export type QuestionnaireProviderType = {
  questionnaire: Questionnaire | null;
  refetch: () => Promise<void>;
};

export const QuestionnaireContext =
  createContext<QuestionnaireProviderType>(null!);

export const QuestionnaireProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [questionnaire, setQuestionnaire] =
    useState<Questionnaire | null>(null);

  useEffect(() => {
    (async () => {
      const questionnaire = await questionnairesApi.getOne({
        id: QUESTIONNAIRE_ID,
      });

      setQuestionnaire(questionnaire);
    })();
  }, []);

  const refetch = async () => {
    const questionnaire = await questionnairesApi.getOne({
      id: QUESTIONNAIRE_ID,
    });

    setQuestionnaire(questionnaire);
  };

  return (
    <QuestionnaireContext.Provider
      value={{ questionnaire, refetch }}
    >
      {children}
    </QuestionnaireContext.Provider>
  );
};
