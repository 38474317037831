import { forwardRef, useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {
  AnimatePresence,
  motion,
  useScroll,
} from 'framer-motion';

import questionnaireAPI from '../../../api/questionnaires';

import { QUESTIONNAIRE_ID } from '../../../constants';

import { GetLevelResponse } from '../../../types/endpoints';

import Step from './Step';

import './style.scss';

export interface StepsProps {
  stickedAt: number;
}

const SkipButton: React.FC<{ sticked: boolean }> = ({
  sticked,
}) => (
  <Box
    className="home-step-skip"
    onClick={() => {
      window.scrollTo({
        left: 0,
        top: document.body.scrollHeight,
      });
    }}
    sx={{
      position: sticked ? 'fixed' : 'absolute',
    }}
    component={motion.div}
    initial={{ opacity: 0.2 }}
    animate={{ opacity: 1.0 }}
    exit={{ opacity: 0.2 }}
  >
    <Typography variant="h5">Omitir</Typography>
    <svg
      width="25"
      height="42"
      viewBox="0 0 25 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24 0V42"
        stroke="white"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M1.15002 4.00006C10.63 4.00006 18.31 21.1501 18.31 21.1501C18.31 21.1501 10.63 38.3 1.15002 38.3"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  </Box>
);

const Steps = forwardRef<HTMLDivElement, StepsProps>(
  ({ stickedAt }, ref) => {
    const [steps, setSteps] = useState<GetLevelResponse>(
      []
    );
    const [headerSticked, setHeaderSticked] =
      useState(false);
    const [currentStep, setCurrentStep] = useState(-1);

    const getStepNumClass = (idx: number) =>
      `step-num${
        idx === currentStep + 1 ? ' current' : ''
      }${idx < currentStep + 1 ? ' past' : ''}`;

    const { scrollY } = useScroll();

    useEffect(() => {
      (async () => {
        const response = await questionnaireAPI.getLevel1(
          QUESTIONNAIRE_ID
        );

        setSteps(
          response.filter(
            (level) => level.name !== 'Perfil'
          )
        );
      })();
    }, []);

    useEffect(() => {
      if (stickedAt === 0) return;

      const onChangeScroll = (y: number) => {
        const onPage = y >= stickedAt;
        setHeaderSticked(onPage);

        if (onPage) {
          const stepY = y - stickedAt;

          setCurrentStep(
            Math.floor(stepY / (window.innerHeight * 0.7))
          );
        } else {
          setCurrentStep(-1);
        }
      };

      onChangeScroll(scrollY.get());
      scrollY.onChange(onChangeScroll);

      return () => {
        scrollY.clearListeners();
      };
    }, [scrollY, stickedAt]);

    return (
      <Box className="home-steps" ref={ref}>
        <Box className="home-steps-header">
          <Box
            className="home-steps-header-content"
            sx={{
              position: headerSticked
                ? 'fixed'
                : 'relative',
              top: headerSticked ? 0 : 'initial',
              left: 0,
            }}
          >
            <Box className="home-steps-nums">
              {steps.map((_, i) => (
                <Box
                  className={getStepNumClass(i)}
                  key={`step-num-${i}`}
                >
                  <Typography variant="h6" component="span">
                    {i + 1}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>

        <AnimatePresence initial={false}>
          {currentStep < steps.length - 1 && (
            <SkipButton
              key="skip-button"
              sticked={headerSticked}
            />
          )}
        </AnimatePresence>

        {steps.map((step, i) => (
          <Step
            key={`home-step-${i}`}
            fixed={i <= currentStep}
            isLast={i === steps.length - 1}
            level={step}
          />
        ))}
      </Box>
    );
  }
);

export default Steps;
