import Radio, { RadioProps } from '@mui/material/Radio';
import { AnimatePresence, motion } from 'framer-motion';

const RadioIcon: React.FC<{ checked?: boolean }> = ({
  checked = false,
}) => (
  <svg
    width="45"
    height="45"
    viewBox="0 0 45 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="22.5"
      cy="22.5"
      r="21"
      fill="white"
      stroke="#F29C00"
      strokeWidth="3"
    />

    {!checked && (
      <motion.circle
        cx="22.5001"
        cy="22.5001"
        r="17.8448"
        fill="#FCCB83"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{
          duration: 0.25,
        }}
      />
    )}

    {checked && (
      <>
        <motion.circle
          cx="22.5"
          cy="22.5"
          r="20"
          fill="#202020"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{
            duration: 0.25,
          }}
        />

        <motion.path
          d="M12.4141 23.2285L19.1616 29.976L33.6205 15.5171"
          stroke="#8ACFBB"
          strokeWidth="3"
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
          exit={{ pathLength: 0 }}
          transition={{
            duration: 0.25,
            delay: 0.1,
          }}
        />
      </>
    )}
  </svg>
);

const MultipleOptionRadio: React.FC<RadioProps> = (
  props
) => (
  <AnimatePresence initial={false}>
    <Radio
      sx={{
        '&:hover': {
          bgcolor: 'transparent',
        },
      }}
      className="questionable-mo-option-radio"
      disableRipple
      color="default"
      icon={<RadioIcon />}
      checkedIcon={<RadioIcon checked />}
      {...props}
    />
  </AnimatePresence>
);

export default MultipleOptionRadio;
