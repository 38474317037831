import { useState } from 'react';

import { AnimatePresence } from 'framer-motion';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import IconButton, {
  IconButtonProps,
} from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { Level1, Level2 } from '../../../types';

import { ICON_LEVEL_MAP } from '../../../constants';

import ProgressNumber from '../../Shared/ProgressNumber';
import ProgressCircle from '../../Shared/ProgressCircle';

import Level2Row from './Level2Row';

import './style.scss';

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(90deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const ExpandMoreIcon: React.FC = () => (
  <svg
    width="8"
    height="15"
    viewBox="0 0 8 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 1L6 7.50254L1 14"
      stroke="#D9C7B6"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
  </svg>
);

const getSublevels = (level: Level1): Level2[] => {
  const all = level.questions.map(
    (question) => question.level2
  );

  return all.filter(
    (value, index, self) =>
      index === self.findIndex((t) => t.id === value.id)
  );
};

export interface LevelCardProps {
  level: Level1;
  showResults: boolean;
}

const LevelCard: React.FC<LevelCardProps> = ({
  level,
  showResults,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [selectedLevel2, setSelectedLevel2] =
    useState<Level2 | null>(null);

  const IconComponent = ICON_LEVEL_MAP[level.name];
  const sublevels = getSublevels(level);

  return (
    <>
      <Card
        className="level-card"
        elevation={4}
        sx={{ borderRadius: 3 }}
      >
        <CardContent
          className="level-card-content"
          onClick={() => {
            setExpanded(!expanded);
          }}
        >
          <Box className="level-progress-circle">
            <ProgressCircle progress={level.progress} />
          </Box>
          <Box className="level-icon">
            <IconComponent />
          </Box>
          <Box className="level-summary">
            <ProgressNumber progress={level.progress} />
            <Box className="level-summary-content">
              <Typography
                variant="h6"
                className="level-summary-title"
              >
                {level.name}
              </Typography>

              <ExpandMore
                expand={expanded}
                onClick={() => {
                  setExpanded(!expanded);
                }}
                aria-expanded={expanded}
                aria-label="show more"
              >
                <ExpandMoreIcon />
              </ExpandMore>
            </Box>
          </Box>
        </CardContent>

        <Collapse
          className="level-summary-collapse"
          in={expanded}
          unmountOnExit
        >
          <Divider />
          {sublevels.map((level2) => (
            <Level2Row
              key={level2.id}
              level1={level}
              level2={level2}
              onSelectLevel={(level2) =>
                setSelectedLevel2(level2)
              }
              onDeselectLevel={() =>
                setSelectedLevel2(null)
              }
              showResults={showResults}
            />
          ))}
        </Collapse>
      </Card>

      <AnimatePresence>
        {selectedLevel2 && (
          <Box
            className="level2-description-modal"
            key="level2-description-modal"
          >
            <Typography
              className="level2-description-title"
              variant="h5"
            >
              {selectedLevel2.name.es}
            </Typography>
            <Typography
              className="level2-description-text"
              variant="h6"
            >
              {selectedLevel2.description.es}
            </Typography>
          </Box>
        )}
      </AnimatePresence>
    </>
  );
};

export default LevelCard;
