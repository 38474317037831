import axios from 'axios';

import {
  PostAnswerResponse,
  PostAnswerRequest,
} from '../types/endpoints';
import { API_URL } from './urls';
import { buildDefaultHeaders } from './utils';

const post = async (
  request: PostAnswerRequest
): Promise<PostAnswerResponse> =>
  axios.post(`${API_URL}/api/question/answer`, request, {
    headers: buildDefaultHeaders(),
  });

const answerApi = {
  post,
};

export default answerApi;
