import {
  ReactElement,
  useContext,
  useEffect,
  useState,
} from 'react';

import { useNavigate } from 'react-router-dom';

import { QuestionnaireContext } from '../../../providers/QuestionnaireProvider';

const RequireProfileCompleted: React.FC<{
  children: ReactElement;
}> = ({ children }) => {
  const [loading, setLoading] = useState(true);

  const { questionnaire } = useContext(
    QuestionnaireContext
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (!questionnaire) return;

    const profileLevel = questionnaire.level1.find(
      (level) => level.name === 'Perfil'
    );

    if (profileLevel && !profileLevel.completed) {
      navigate('/dashboard/profile', { replace: true });
    }

    setLoading(false);
  }, [navigate, questionnaire]);

  if (!questionnaire || loading) return null;

  return children;
};

export default RequireProfileCompleted;
