import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import './style.scss';

export interface ThermometerProps {
  onClick?: () => void;
}

const Thermometer: React.FC<ThermometerProps> = ({
  onClick,
}) => (
  <Box className="thermometer" onClick={onClick}>
    <Typography variant="h6">Ver resultados</Typography>
  </Box>
);

export default Thermometer;
