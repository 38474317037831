import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';

import StartIcon from '../../Icons/StartIcon';

import { ICON_LEVEL_MAP } from '../../../constants';

import { GetLevelResponse } from '../../../types/endpoints';

export interface StepProps {
  fixed: boolean;
  isLast: boolean;
  level: GetLevelResponse[0];
}

const Step: React.FC<StepProps> = ({
  fixed,
  isLast,
  level,
}) => {
  const IconComponent = ICON_LEVEL_MAP[level.name];

  return (
    <Box
      className="home-step"
      sx={{
        height: isLast ? '100vh' : '70vh',
      }}
    >
      <Box
        className="home-step-content"
        sx={{
          position: fixed ? 'fixed' : 'relative',
          top: fixed ? (isLast ? 0 : 140) : 'initial',
          height: isLast ? '100vh' : '70vh',
        }}
      >
        <Box className="home-step-icon">
          {IconComponent && <IconComponent />}
        </Box>

        <Typography
          className="home-step-title"
          variant="h4"
        >
          {level.name}
        </Typography>

        <Typography
          className="home-step-description"
          variant="h5"
        >
          {level.es}
        </Typography>

        {!isLast && <Box className="home-step-separator" />}

        {isLast && (
          <Box className="home-step-buttons">
            <Button
              className="home-begin"
              variant="contained"
              component={Link}
              to="/dashboard"
              startIcon={<StartIcon />}
            >
              <Typography variant="h5">COMENZAR</Typography>
            </Button>

            <Button
              className="home-how-to"
              variant="contained"
              disableElevation
              component={Link}
              to="/how-to"
            >
              <Typography variant="h6">
                ¿Cómo utilizar BISION?
              </Typography>
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Step;
