import React from 'react';

import WellnessIcon from '../components/Icons/WellnessIcon';
import PurposeIcon from '../components/Icons/PurposeIcon';
import HealthIcon from '../components/Icons/HealthIcon';
import DignityIcon from '../components/Icons/DignityIcon';
import VulnerabilityIcon from '../components/Icons/VulnerabilityIcon';
import ProfileIcon from '../components/Icons/ProfileIcon';

export const ICON_LEVEL_MAP: {
  [key: string]: React.FC;
} = {
  Perfil: ProfileIcon,
  'Bien & Estar': WellnessIcon,
  'Propósito y fuerza de carácter': PurposeIcon,
  'Salud 360°': HealthIcon,
  'Vivencia de la dignidad Humana': DignityIcon,
  'Condiciones de vulnerabilidad': VulnerabilityIcon,
};

export const QUESTIONNAIRE_ID =
  '86b20290-07ed-11ed-bcae-d3b7eac03ff7';
