const polarToCartesian = (
  x: number,
  y: number,
  radius: number,
  angle: number
): { x: number; y: number } => ({
  x: x + radius * Math.cos(angle),
  y: y + radius * Math.sin(angle),
});

const getSliceD = (
  x: number,
  y: number,
  radius: number,
  startAngle: number,
  endAngle: number
): string => {
  const first = polarToCartesian(x, y, radius, startAngle);
  const second = polarToCartesian(x, y, radius, endAngle);

  return `M${x},${y} L${first.x},${first.y} A${radius},${radius} 0 0,1 ${second.x},${second.y} Z`;
};

const arcPathD = (
  x: number,
  y: number,
  radius: number,
  startAngle: number,
  endAngle: number
) => {
  const start = polarToCartesian(x, y, radius, endAngle);
  const end = polarToCartesian(x, y, radius, startAngle);

  return `M${start.x},${start.y} A${radius},${radius} 0 0,0 ${end.x},${end.y}`;
};

const constrain = (val: number, min: number, max: number) =>
  val < min ? min : val > max ? max : val;

const map = (
  x: number,
  minA: number,
  maxA: number,
  minB: number,
  maxB: number
) => ((x - minA) / (maxA - minA)) * (maxB - minB) + minB;

const toPercentage = (progress: number) =>
  `${Math.round(progress * 100)}%`;

export {
  polarToCartesian,
  getSliceD,
  arcPathD,
  constrain,
  toPercentage,
  map,
};
