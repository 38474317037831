import { useEffect, useState } from 'react';

import {
  Controller,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { QuestionableMultipleOption } from '../../../types';
import {
  MultipleOptionAnswer,
  OpenTextAnswer,
} from '../../../types/endpoints';
import { multipleOptionFormSchema } from '../../../schemas';

import SubmitButton from '../../Shared/SubmitButton';
import MultipleOptionRadio from './MultipleOptionRadio';

const isOtherLabel = (label: string) =>
  label === 'Otra (indicar)' || label === 'Otro (indicar)';

export interface MultipleOptionFormInput {
  optionSelected: string;
  text: string;
  otherSelected: boolean;
}

export interface MultipleOptionFormProps {
  questionable: QuestionableMultipleOption;
  onSubmit: (
    answer: MultipleOptionAnswer | OpenTextAnswer
  ) => void;
  onSelection: (i: number) => void;
  submitting: boolean;
}

const MultipleOptionForm: React.FC<
  MultipleOptionFormProps
> = ({
  questionable,
  onSubmit,
  onSelection,
  submitting,
}) => {
  const [selectOther, setSelectOther] = useState(false);

  const {
    handleSubmit,
    control,
    formState,
    reset,
    setValue,
  } = useForm<MultipleOptionFormInput>({
    mode: 'onChange',
    defaultValues: {
      optionSelected: '',
      otherSelected: false,
      text: '',
    },
    resolver: yupResolver(multipleOptionFormSchema),
  });

  const onSubmitForm: SubmitHandler<
    MultipleOptionFormInput
  > = (data) => {
    onSubmit(
      data.otherSelected
        ? {
            answerableType: 'OpenText',
            text: data.text,
          }
        : {
            answerableType: 'MultipleOption',
            options: [data.optionSelected],
          }
    );
  };

  useEffect(() => {
    reset();
    setSelectOther(false);
    window.scrollTo(0, 0);
  }, [reset, questionable.id]);

  const options = questionable.options.sort(
    (q1, q2) => q1.sort - q2.sort
  );

  return (
    <Box
      className="questionable-form questionable-mo-form"
      component="form"
      onSubmit={handleSubmit(onSubmitForm)}
    >
      <FormControl>
        <Controller
          name="optionSelected"
          control={control}
          render={({ field }) => (
            <RadioGroup
              {...field}
              onChange={(evt) => {
                const index = options.findIndex(
                  (q) => q.id === evt.currentTarget.value
                );

                const otherSelected = isOtherLabel(
                  options[index].content.es
                );
                setSelectOther(otherSelected);
                setValue('otherSelected', otherSelected);

                onSelection(index);
                field.onChange(evt);
              }}
            >
              {options.map((option) => (
                <Box
                  className="questionable-mo-option-container"
                  key={option.id}
                >
                  <FormControlLabel
                    className="questionable-mo-option"
                    value={option.id}
                    label={
                      <Typography
                        className="questionable-mo-option-text"
                        variant="h6"
                      >
                        {option.content.es}
                      </Typography>
                    }
                    control={<MultipleOptionRadio />}
                  />
                  {isOtherLabel(option.content.es) && (
                    <Controller
                      name="text"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          className="open-text-field"
                          variant="outlined"
                          placeholder="Descríbelo..."
                          disabled={!selectOther}
                        />
                      )}
                    />
                  )}
                </Box>
              ))}
            </RadioGroup>
          )}
        />
      </FormControl>

      <SubmitButton
        disabled={!formState.isValid}
        loading={submitting}
      />
    </Box>
  );
};

export default MultipleOptionForm;
