import { useEffect, useRef } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { motion } from 'framer-motion';
import JSConfetti from 'js-confetti';

const CelebrateFinish: React.FC<{
  onClose: () => void;
}> = ({ onClose }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (canvasRef.current) {
      const jsConfetti = new JSConfetti({
        canvas: canvasRef.current,
      });
      jsConfetti.addConfetti();
    }
  }, []);

  return (
    <Box
      key="celebrate-finish"
      className="celebrate-finish"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer - 1,
      }}
      component={motion.div}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      onClick={onClose}
    >
      <Box className="celebrate-finish-modal">
        <Box
          className="celebrate-finish-close"
          onClick={onClose}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              x1="22.1677"
              y1="1.76777"
              x2="1.76767"
              y2="22.1678"
              stroke="white"
              strokeWidth="5"
            />
            <line
              x1="22.2322"
              y1="22.1678"
              x2="1.83224"
              y2="1.76779"
              stroke="white"
              strokeWidth="5"
            />
          </svg>
        </Box>
        <Typography
          className="celebrate-finish-title"
          variant="h5"
        >
          COMPLETASTE ESTA SECCIÓN
        </Typography>
        <Box className="celebrate-finish-icon">
          <svg
            width="58"
            height="74"
            viewBox="0 0 58 74"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M34.0701 50.1701V34.2401C34.0701 34.2401 44.7401 27.2401 43.5101 20.6601C43.484 20.539 43.4238 20.4279 43.3366 20.3398C43.2495 20.2518 43.1389 20.1905 43.0181 20.1632C42.8972 20.1358 42.7711 20.1436 42.6545 20.1856C42.538 20.2276 42.4358 20.302 42.3601 20.4001C40.3601 23.0101 34.6201 29.7101 28.9001 29.7101C23.1801 29.7101 17.4801 22.9601 15.4801 20.3501C15.4045 20.252 15.3023 20.1776 15.1857 20.1356C15.0692 20.0936 14.943 20.0859 14.8222 20.1132C14.7013 20.1405 14.5908 20.2018 14.5036 20.2898C14.4165 20.3779 14.3563 20.489 14.3301 20.6101C13.0501 27.2401 23.7301 34.2401 23.7301 34.2401V48.7501"
              fill="white"
            />
            <path
              d="M34.0701 50.1701V34.2401C34.0701 34.2401 44.7401 27.2401 43.5101 20.6601C43.484 20.539 43.4238 20.4279 43.3366 20.3398C43.2495 20.2518 43.1389 20.1905 43.0181 20.1632C42.8972 20.1358 42.7711 20.1436 42.6545 20.1856C42.538 20.2276 42.4358 20.302 42.3601 20.4001C40.3601 23.0101 34.6201 29.7101 28.9001 29.7101C23.1801 29.7101 17.4801 22.9601 15.4801 20.3501C15.4045 20.252 15.3023 20.1776 15.1857 20.1356C15.0692 20.0936 14.943 20.0859 14.8222 20.1132C14.7013 20.1405 14.5908 20.2018 14.5036 20.2898C14.4165 20.3779 14.3563 20.489 14.3301 20.6101C13.0501 27.2401 23.7301 34.2401 23.7301 34.2401V48.7501"
              stroke="#4FB3E2"
              strokeWidth="2.94"
              strokeMiterlimit="10"
              strokeLinecap="round"
            />
            <path
              d="M28.9004 42.27V49.77"
              stroke="#4FB3E2"
              strokeWidth="2.94"
              strokeMiterlimit="10"
              strokeLinecap="round"
            />
            <path
              d="M28.9004 25.1801C31.4077 25.1801 33.4404 23.1475 33.4404 20.6401C33.4404 18.1327 31.4077 16.1001 28.9004 16.1001C26.393 16.1001 24.3604 18.1327 24.3604 20.6401C24.3604 23.1475 26.393 25.1801 28.9004 25.1801Z"
              fill="white"
              stroke="#4FB3E2"
              strokeWidth="2.94"
              strokeMiterlimit="10"
              strokeLinecap="round"
            />
            <path
              d="M2 71.67L17.26 47.76L44.57 52.61L55.55 71.67"
              fill="#D34F7A"
            />
            <path
              d="M2 71.67L17.26 47.76L44.57 52.61L55.55 71.67"
              stroke="#8C1A3C"
              strokeWidth="2.94"
              strokeMiterlimit="10"
              strokeLinecap="round"
            />
            <path
              d="M10.48 71.6701L17.83 61.0901"
              stroke="#8C1A3C"
              strokeWidth="2.94"
              strokeMiterlimit="10"
              strokeLinecap="round"
            />
            <path
              d="M48.6097 71.6701L44.9697 66.3801"
              stroke="#8C1A3C"
              strokeWidth="2.94"
              strokeMiterlimit="10"
              strokeLinecap="round"
            />
            <path
              d="M28.9004 2V6.5"
              stroke="#FCB542"
              strokeWidth="2.94"
              strokeMiterlimit="10"
              strokeLinecap="round"
            />
            <path
              d="M44.5002 6.08008L41.9502 9.80008"
              stroke="#FCB542"
              strokeWidth="2.94"
              strokeMiterlimit="10"
              strokeLinecap="round"
            />
            <path
              d="M13.1699 6.08008L15.7199 9.80008"
              stroke="#FCB542"
              strokeWidth="2.94"
              strokeMiterlimit="10"
              strokeLinecap="round"
            />
          </svg>
        </Box>
        <Typography
          className="celebrate-finish-description"
          variant="h5"
        >
          ¡CONTINÚA!
        </Typography>
      </Box>
      <Box
        className="celebrate-finish-confetti"
        component="canvas"
        ref={canvasRef}
      />
    </Box>
  );
};

export default CelebrateFinish;
