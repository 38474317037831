import { Routes, Route } from 'react-router-dom';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';

import theme from './theme';

import './styles/fonts.scss';
import './App.scss';

import { AuthProvider } from './providers/AuthProvider';
import { QuestionnaireProvider } from './providers/QuestionnaireProvider';

import DashboardLayout from './layout/DashboardLayout';

import RequireAuth from './components/Shared/RequireAuth';

import Home from './pages/Home';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Evaluations from './pages/Evaluations';
import Evaluation from './pages/Evaluation';
import EmailVerify from './pages/EmailVerify';
import Profile from './pages/Profile';
import HowTo from './pages/HowTo';
import About from './pages/About';

const App: React.FC = () => (
  <AuthProvider>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box className="app">
        <Routes>
          <Route path="/" element={<Home />} />

          <Route path="login">
            <Route index element={<Login />} />
            <Route
              path="email/verify"
              element={<EmailVerify />}
            />
          </Route>

          <Route path="how-to" element={<HowTo />} />
          <Route path="about" element={<About />} />

          <Route
            path="dashboard"
            element={
              <RequireAuth>
                <QuestionnaireProvider>
                  <DashboardLayout />
                </QuestionnaireProvider>
              </RequireAuth>
            }
          >
            <Route index element={<Dashboard />} />
            <Route
              path="evaluations/:level1Id/:level2Id"
              element={<Evaluation />}
            />
            <Route
              path="evaluations"
              element={<Evaluations />}
            />
            <Route path="profile" element={<Profile />} />
          </Route>
        </Routes>
      </Box>
    </ThemeProvider>
  </AuthProvider>
);

export default App;
