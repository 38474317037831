const HealthIcon: React.FC = () => (
  <svg
    width="57"
    height="61"
    viewBox="0 0 57 61"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28.5802 18.3C24.0791 18.3 20.4302 14.6511 20.4302 10.15C20.4302 5.64888 24.0791 2 28.5802 2C33.0813 2 36.7302 5.64888 36.7302 10.15C36.7302 14.6511 33.0813 18.3 28.5802 18.3Z"
      fill="#FCB542"
      stroke="#A54605"
      strokeWidth="2.94"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M28.58 19.5901H23.58C21.481 19.5886 19.4372 20.2617 17.75 21.5101C16.9125 22.1292 16.1798 22.8787 15.58 23.7301L11.8399 29.0201H5.99995C4.93908 29.0201 3.92167 29.4415 3.17152 30.1917C2.42138 30.9418 1.99995 31.9592 1.99995 33.0201C1.99995 34.0809 2.42138 35.0984 3.17152 35.8485C3.92167 36.5987 4.93908 37.0201 5.99995 37.0201H14.93C15.1601 37.0205 15.3865 36.9608 15.5864 36.8468C15.7864 36.7328 15.9531 36.5685 16.07 36.3701L19.7099 30.2201C19.7523 30.1562 19.8138 30.1075 19.8857 30.0811C19.9576 30.0546 20.036 30.0518 20.1097 30.073C20.1833 30.0942 20.2482 30.1383 20.295 30.1989C20.3418 30.2595 20.3681 30.3335 20.37 30.4101V41.3301C20.3699 41.5386 20.3283 41.7451 20.2476 41.9374C20.167 42.1297 20.0488 42.304 19.9 42.4501L10.9 51.2101C10.2537 51.8404 9.81006 52.6491 9.62568 53.5328C9.4413 54.4166 9.52457 55.3352 9.86483 56.1713C10.2051 57.0075 10.7869 57.7232 11.5359 58.2271C12.285 58.7309 13.1672 59.0001 14.07 59.0001H43.07C43.9723 59.0021 44.8548 58.735 45.6047 58.2332C46.3546 57.7313 46.938 57.0173 47.2803 56.1824C47.6226 55.3475 47.7084 54.4296 47.5267 53.5457C47.3449 52.6619 46.9039 51.8522 46.26 51.2201L37.26 42.4601C37.1112 42.314 36.9929 42.1397 36.9123 41.9474C36.8316 41.7551 36.79 41.5486 36.79 41.3401V30.3801C36.7918 30.3035 36.8181 30.2295 36.8649 30.1689C36.9117 30.1083 36.9766 30.0642 37.0503 30.043C37.1239 30.0218 37.2023 30.0246 37.2742 30.0511C37.3461 30.0775 37.4076 30.1262 37.4499 30.1901L41.1299 36.4501C41.2468 36.6484 41.4135 36.8128 41.6135 36.9268C41.8134 37.0408 42.0397 37.1006 42.2699 37.1001H51.1999C52.2608 37.1001 53.2782 36.6787 54.0284 35.9285C54.7785 35.1784 55.2 34.161 55.2 33.1001C55.2 32.0392 54.7785 31.0218 54.0284 30.2717C53.2782 29.5215 52.2608 29.1001 51.1999 29.1001H45.3099L41.57 23.8101C40.9701 22.9588 40.2374 22.2092 39.4 21.5901C37.7127 20.3417 35.6689 19.6686 33.57 19.6701L28.58 19.5901Z"
      fill="#FCB542"
      stroke="#A54605"
      strokeWidth="2.94"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M21.1498 49.8901H31.4798C32.6804 49.8954 33.83 50.3761 34.6771 51.2269C35.5242 52.0778 35.9998 53.2296 35.9998 54.4302C35.9998 55.6343 35.5214 56.789 34.67 57.6404C33.8186 58.4919 32.6638 58.9702 31.4598 58.9702H21.1498"
      fill="#FCB542"
    />
    <path
      d="M21.1498 49.8901H31.4798C32.6804 49.8954 33.83 50.3761 34.6771 51.2269C35.5242 52.0778 35.9998 53.2296 35.9998 54.4302V54.4302C35.9998 55.6343 35.5214 56.789 34.67 57.6404C33.8186 58.4919 32.6638 58.9702 31.4598 58.9702H21.1498"
      stroke="#A54605"
      strokeWidth="2.94"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);

export default HealthIcon;
