import { useEffect, useState } from 'react';

import Box from '@mui/material/Box';

import answerApi from '../../../api/answer';
import { Level1, Question } from '../../../types';
import {
  MultipleOptionAnswer,
  OpenTextAnswer,
  RatingScaleAnswer,
} from '../../../types/endpoints';

import MultipleOptionForm from '../MultipleOptionForm';
import OpenTextForm from '../OpenTextForm';
import RatingScaleForm from '../RatingScaleForm';

import './style.scss';

export interface QuestionableFormProps {
  level1: Level1;
  question: Question;
  paddingTop: number;
  onAnswered: () => void;
  onSelection: (i: number, j: number) => void;
}

const QuestionableForm: React.FC<QuestionableFormProps> = ({
  level1,
  question,
  paddingTop,
  onAnswered,
  onSelection,
}) => {
  const [submitting, setSubmitting] = useState(false);

  const submitAnswer = async (
    answer:
      | MultipleOptionAnswer
      | RatingScaleAnswer
      | OpenTextAnswer
  ) => {
    setSubmitting(true);

    await answerApi.post({
      answer: {
        level1: level1.id,
        questionId: question.id,
        questionableId:
          question.questionable_type !== 'OpenText'
            ? question.questionable.id
            : '',
        ...answer,
      },
    });

    onAnswered();
  };

  useEffect(() => {
    setSubmitting(false);
  }, [question.id]);

  return (
    <Box
      className="questionable-display"
      sx={{
        background:
          question.questionable_type === 'RatingScale'
            ? '#FFF2DF'
            : '#DCF3F2',
        paddingTop: `${paddingTop}px`,
      }}
    >
      {question.questionable_type === 'MultipleOption' && (
        <MultipleOptionForm
          questionable={question.questionable}
          onSubmit={submitAnswer}
          onSelection={(i) => {
            onSelection(0, i);
          }}
          submitting={submitting}
        />
      )}
      {question.questionable_type === 'RatingScale' && (
        <RatingScaleForm
          question={question}
          onSubmit={submitAnswer}
          onSelection={(i, j) => onSelection(i, j)}
          submitting={submitting}
        />
      )}
      {question.questionable_type === 'OpenText' && (
        <OpenTextForm
          question={question}
          onSubmit={submitAnswer}
          submitting={submitting}
        />
      )}
    </Box>
  );
};

export default QuestionableForm;
