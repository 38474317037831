const MenuIcon: React.FC = () => (
  <svg
    width="41"
    height="33"
    viewBox="0 0 41 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.26001 3.26001H37.61"
      stroke="#F29C00"
      strokeWidth="6.53"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M3.26001 16.38H37.61"
      stroke="#8C1A3C"
      strokeWidth="6.53"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M3.26001 29.5H37.61"
      stroke="#4FB3E2"
      strokeWidth="6.53"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);

export default MenuIcon;
