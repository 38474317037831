const CloseIcon: React.FC = () => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.689941 0.689941L20.0099 20.0099"
      stroke="#A0A0A0"
      strokeWidth="1.38"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M20.0099 0.689941L0.689941 20.0099"
      stroke="#A0A0A0"
      strokeWidth="1.38"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);

export default CloseIcon;
