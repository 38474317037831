import { useContext } from 'react';
import { Navigate } from 'react-router-dom';

import Box from '@mui/material/Box';

import QuestionDisplay from '../../components/Evaluations/QuestionDisplay';

import { QuestionnaireContext } from '../../providers/QuestionnaireProvider';

import { selectEvaluationData } from '../../selectors';

import './style.scss';

const Profile: React.FC = () => {
  const { questionnaire, refetch } = useContext(
    QuestionnaireContext
  );

  if (!questionnaire) {
    return null;
  }

  const profileLevel = questionnaire.level1.find(
    (level) => level.name === 'Perfil'
  );

  if (!profileLevel) {
    return null;
  }

  const { questions, progress } = selectEvaluationData(
    questionnaire,
    profileLevel.id
  );

  if (questions.length === 0) {
    return <Navigate to="/dashboard" replace />;
  }

  return (
    <Box className="profile">
      <QuestionDisplay
        level1={profileLevel}
        question={questions[0]}
        progress={progress}
        lastQuestion={questions.length === 1}
        onAnswered={async () => {
          await refetch();
        }}
      />
    </Box>
  );
};

export default Profile;
