import axios from 'axios';

import { API_URL } from './urls';

const registerEmail = async (
  email: string
): Promise<{
  data: { message: string; bypassToken: string };
}> => axios.post(`${API_URL}/login/email?email=${email}`);

const verifyToken = async (
  token: string
): Promise<{ data: { message: string } }> =>
  axios.get(
    `${API_URL}/login/email/verify?token=${encodeURIComponent(
      token
    )}`
  );

const authAPI = { registerEmail, verifyToken };

export default authAPI;
