import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import LoginForm from '../../components/Login/LoginForm';
import BISIONLogo from '../../components/Misc/BISIONLogo';

import './style.scss';

const Login: React.FC = () => (
  <Box className="login">
    <Container
      component="main"
      maxWidth="md"
      className="login-main"
      disableGutters
    >
      <Paper className="login-form-container" elevation={4}>
        <BISIONLogo />
        <Typography className="login-title" variant="h4">
          BISION
        </Typography>
        <Typography className="login-copy" variant="h6">
          la plataforma para tu bien &amp; estar
        </Typography>

        <LoginForm />
      </Paper>
    </Container>
  </Box>
);

export default Login;
