import axios from 'axios';

import {
  GetLevelResponse,
  GetLevelResultsResponse,
  GetQuestionnaireRequest,
  GetQuestionnaireResponse,
} from '../types/endpoints';
import { API_URL } from './urls';
import { buildDefaultHeaders } from './utils';

const getOne = async ({
  id,
  extended = true,
}: GetQuestionnaireRequest): Promise<GetQuestionnaireResponse> => {
  const response = await axios.get(
    `${API_URL}/api/questionaire/${id}?extended=${extended}`,
    {
      headers: buildDefaultHeaders(),
    }
  );

  return response.data;
};

const getLevel1 = async (
  id: string
): Promise<GetLevelResponse> => {
  const response = await axios.get(
    `${API_URL}/api/questionaire/${id}/level1`
  );

  return response.data;
};

const getLevelResults = async (
  id: string
): Promise<GetLevelResultsResponse> => {
  const response = await axios.get(
    `${API_URL}/api/result/${id}`,
    {
      headers: buildDefaultHeaders(),
    }
  );

  return response.data;
};

const questionnaireAPI = {
  getOne,
  getLevel1,
  getLevelResults,
};

export default questionnaireAPI;
