import Typography from '@mui/material/Typography';

import { toPercentage } from '../../../utils';

const percentageColor = (progress: number) =>
  progress >= 1.0 ? '#8ACFBB' : '#BFBFBF';

const ProgressNumber: React.FC<{
  progress: number;
}> = ({ progress }) => {
  return (
    <Typography
      className="progress-number"
      sx={{
        color: percentageColor(progress),
        fontWeight: 700,
        lineHeight: '1.1875rem',
      }}
    >
      {toPercentage(progress)}
    </Typography>
  );
};

export default ProgressNumber;
