const InfoIcon: React.FC = () => (
  <svg
    width="27"
    height="27"
    viewBox="0 0 27 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="13.1364"
      cy="13.5533"
      r="13.1364"
      fill="#A887F5"
    />
    <path
      d="M10.3337 11.9629C11.5967 11.8327 12.6969 11.5658 13.6344 11.1621C13.7972 11.2272 13.9111 11.3118 13.9762 11.416C13.9762 14.7949 14.0023 17.2103 14.0544 18.6621C14.0609 18.8314 14.1097 18.9779 14.2008 19.1016C14.2985 19.2188 14.4352 19.3099 14.611 19.375C14.7868 19.4401 14.9333 19.4889 15.0505 19.5215C15.1676 19.5475 15.3206 19.5736 15.5094 19.5996C15.5485 19.6777 15.568 19.7591 15.568 19.8438C15.568 19.9284 15.5485 20 15.5094 20.0586C14.6501 20.0195 13.8167 20 13.0094 20C12.2152 20 11.3883 20.0195 10.529 20.0586C10.5029 19.9544 10.4899 19.8828 10.4899 19.8438C10.4899 19.8112 10.5029 19.7298 10.529 19.5996C10.7438 19.5736 10.9196 19.5475 11.0563 19.5215C11.193 19.4889 11.3623 19.4401 11.5641 19.375C11.7659 19.3099 11.9189 19.2188 12.0231 19.1016C12.1273 18.9779 12.1826 18.8314 12.1891 18.6621C12.2152 18.0176 12.2282 17.2363 12.2282 16.3184C12.2282 16.0124 12.2249 15.4915 12.2184 14.7559C12.2119 14.0202 12.2087 13.3757 12.2087 12.8223C11.8376 12.5944 11.2126 12.4609 10.3337 12.4219C10.3076 12.3177 10.2946 12.2363 10.2946 12.1777C10.2946 12.1387 10.3076 12.0671 10.3337 11.9629ZM11.8669 7.88086C11.8669 7.54883 11.984 7.26562 12.2184 7.03125C12.4593 6.79688 12.7425 6.67969 13.068 6.67969C13.4001 6.67969 13.6833 6.79688 13.9176 7.03125C14.152 7.26562 14.2692 7.54883 14.2692 7.88086C14.2692 8.20638 14.152 8.48958 13.9176 8.73047C13.6833 8.96484 13.4001 9.08203 13.068 9.08203C12.7425 9.08203 12.4593 8.96484 12.2184 8.73047C11.984 8.48958 11.8669 8.20638 11.8669 7.88086Z"
      fill="white"
    />
  </svg>
);

export default InfoIcon;
