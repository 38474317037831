import { useContext, useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import { motion } from 'framer-motion';

import quotesAPI from '../../api/quotes';

import DashboardCircle from '../../components/Dashboard/DashboardCircle';
import InfoIcon from '../../components/Icons/InfoIcon';
import RequireProfileCompleted from '../../components/Shared/RequireProfileCompleted';

import { QuestionnaireContext } from '../../providers/QuestionnaireProvider';

import './style.scss';

const LeavesImg: React.FC = () => (
  <svg
    width="124"
    height="177"
    viewBox="0 0 124 177"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <motion.g
      style={{ originX: 0.5, originY: 1 }}
      whileTap={{ rotate: -15, scale: 1.05 }}
      whileHover={{ rotate: -15, scale: 1.05 }}
      transition={{
        type: 'spring',
        stiffness: 800,
        damping: 30,
      }}
    >
      <path
        d="M74.2766 26.2138C74.2766 26.2138 141.751 72.8877 71.9869 134.902C71.9869 134.902 9.33805 78.5973 74.2766 26.2138Z"
        fill="#008E77"
      />
      <path
        d="M75.195 148.24C74.762 144.321 74.5253 141.001 74.3972 138.644C74.3312 137.464 74.2939 136.529 74.258 135.892C74.2221 135.256 74.2308 134.919 74.2275 134.899L73.3529 134.913L74.2297 134.932L76.3476 34.4067C76.3501 34.292 76.33 34.178 76.2883 34.071C76.2466 33.9641 76.1842 33.8665 76.1046 33.7837C76.025 33.701 75.9297 33.6347 75.8244 33.5887C75.7191 33.5427 75.6057 33.5179 75.4908 33.5157C75.2589 33.5116 75.0348 33.5992 74.8674 33.7596C74.6999 33.9199 74.6029 34.1398 74.5974 34.3713L72.4764 134.897L72.482 134.93"
        fill="#1A3873"
      />
    </motion.g>

    <motion.g
      style={{ originX: 0.5, originY: 1 }}
      whileTap={{ rotate: 15, scaleY: 1.05 }}
      whileHover={{ rotate: 15, scaleY: 1.05 }}
      transition={{
        type: 'spring',
        stiffness: 300,
        damping: 20,
      }}
    >
      <path
        d="M14.0294 9.26556C9.55897 15.3111 7.68046 25.2639 12.1143 31.7799C15.8949 37.3465 21.9946 41.6596 17.1238 48.8886C12.8235 55.2843 5.43716 57.4781 2.48819 65.1789C-0.686609 73.4677 0.531028 81.3912 6.97452 87.6286C12.1378 92.625 23.84 97.969 20.5048 106.858C17.9397 113.687 9.67041 116.079 7.87882 123.335C6.27882 129.843 6.82886 137.918 13.9379 140.264C21.9083 142.888 28.0266 145.038 32.6355 152.496C37.3402 160.103 41.0032 168.382 44.0237 176.785C48.2043 171.381 48.8152 165.25 51.469 159.17C53.9 153.598 58.8323 149.407 62.534 144.671C66.9104 139.088 69.6278 133.999 70.4334 126.846C70.8021 123.635 71.0419 120.12 69.4966 117.161C68.0035 114.292 65.168 112.247 63.2806 109.626C58.4723 102.988 59.9732 96.1951 62.2411 88.8973C64.1038 82.8905 68.7963 76.9333 68.2861 70.4688C67.6554 62.5642 62.1263 57.807 56.1006 53.5943C49.7047 49.1291 48.8809 44.3324 51.3641 37.1042C53.9028 29.7134 59.4858 22.3986 55.4216 14.5214C52.1938 8.27078 43.7467 0.580679 36.3107 1.00937C28.3137 1.51114 19.213 2.26567 14.0294 9.26556Z"
        fill="#20A37E"
      />
      <path
        d="M45.7815 173.118C45.6843 172.553 45.5613 171.992 45.4544 171.428L45.4389 171.337C42.4851 161.44 40.019 151.496 38.0405 141.504C30.1878 102.193 29.4298 62.2517 32.9559 22.1503C32.9665 22.0359 32.9543 21.9207 32.9201 21.8111C32.886 21.7015 32.8304 21.5997 32.7567 21.5116C32.683 21.4234 32.5926 21.3507 32.4907 21.2975C32.3887 21.2444 32.2773 21.2118 32.1627 21.2017C31.9311 21.1817 31.701 21.2542 31.5228 21.4033C31.3446 21.5525 31.2329 21.7661 31.2123 21.9972C27.6608 62.2227 28.4288 102.339 36.3218 141.846C38.3261 151.884 40.8063 161.885 43.7626 171.848L44.6 171.598L43.7411 171.762C43.8394 172.334 43.957 172.903 44.071 173.469"
        fill="#1A3873"
      />
    </motion.g>

    <motion.g
      style={{ originX: 0, originY: 0.85 }}
      whileTap={{ rotate: -10 }}
      whileHover={{ rotate: -10 }}
      transition={{
        type: 'spring',
        stiffness: 500,
        damping: 20,
      }}
    >
      <path
        d="M114.879 66.9049C114.879 66.9049 154.929 138.431 64.98 163.572C64.98 163.572 33.5043 85.5076 114.879 66.9049Z"
        fill="#08AD95"
      />
      <path
        d="M113.137 75.2068L93.6187 113L92.079 115.977L87.8026 124.259L86.8623 126.084L80.3088 138.776L78.7699 141.756L66.9869 164.578L66.9719 164.607C66.9307 164.697 66.7942 164.97 66.5826 165.466C66.3195 166.05 65.943 166.907 65.4812 167.984C64.5582 170.163 63.3033 173.263 61.974 176.959L65.3874 163.828L65.4024 163.798L78.5113 138.406L80.0509 135.43L84.8067 126.231L85.7469 124.405L91.8221 112.637L93.3617 109.66L111.56 74.4096C111.613 74.3076 111.685 74.2169 111.773 74.1428C111.861 74.0687 111.962 74.0126 112.072 73.9777C112.181 73.9428 112.297 73.9298 112.411 73.9395C112.526 73.9492 112.637 73.9813 112.739 74.034C112.843 74.0845 112.935 74.1551 113.012 74.2415C113.088 74.3279 113.146 74.4285 113.183 74.5375C113.22 74.6465 113.235 74.7618 113.227 74.8767C113.219 74.9916 113.189 75.1038 113.137 75.2068Z"
        fill="#1A3873"
      />
      <path
        d="M91.5206 84.1476L93.3844 109.657L93.6246 112.996L92.085 115.973L91.8443 112.63L89.7738 84.2679C89.7655 84.1535 89.7799 84.0385 89.816 83.9296C89.8522 83.8207 89.9095 83.72 89.9848 83.6332C90.06 83.5464 90.1516 83.4752 90.2544 83.4238C90.3572 83.3724 90.4691 83.3417 90.5838 83.3335C90.6991 83.3244 90.8151 83.3384 90.925 83.3746C91.0348 83.4108 91.1362 83.4685 91.2234 83.5443C91.3106 83.6201 91.3819 83.7125 91.4329 83.8161C91.4839 83.9196 91.5137 84.0323 91.5206 84.1476Z"
        fill="#1A3873"
      />
      <path
        d="M77.8074 104.333L79.3091 124.879L79.4355 126.624L80.0797 135.442L80.3198 138.781L78.7808 141.761L78.5407 138.421L77.6927 126.767L77.563 125.023L76.0647 104.476C76.0559 104.361 76.0698 104.246 76.1058 104.136C76.1417 104.027 76.1988 103.926 76.274 103.839C76.3492 103.752 76.441 103.68 76.544 103.629C76.6469 103.577 76.759 103.546 76.8739 103.538C77.1028 103.522 77.3287 103.597 77.5032 103.745C77.6777 103.894 77.7869 104.105 77.8074 104.333Z"
        fill="#1A3873"
      />
      <path
        d="M110.921 123.436C110.929 123.55 110.915 123.666 110.879 123.775C110.844 123.884 110.786 123.985 110.711 124.072C110.636 124.159 110.544 124.231 110.441 124.283C110.338 124.334 110.226 124.365 110.111 124.373L94.5414 125.517L92.7957 125.644L86.8681 126.08L84.8234 126.231L85.7638 124.406L87.8091 124.257L92.6597 123.9L94.4085 123.773L109.978 122.629C110.093 122.62 110.209 122.634 110.319 122.67C110.428 122.706 110.53 122.762 110.617 122.837C110.705 122.912 110.776 123.004 110.828 123.106C110.881 123.209 110.912 123.321 110.921 123.436Z"
        fill="#1A3873"
      />
    </motion.g>
  </svg>
);

const Dashboard: React.FC = () => {
  const [showGraphs, setShowGraphs] = useState(false);
  const [showQuote, setShowQuote] = useState(false);
  const [dailyQuote, setDailyQuote] = useState<{
    quote: string;
    author: string;
  }>({
    quote: '',
    author: '',
  });

  const { questionnaire } = useContext(
    QuestionnaireContext
  );

  useEffect(() => {
    (async () => {
      const { data: quote } = await quotesAPI.get();
      setDailyQuote({
        quote: quote.quote.quote,
        author: quote.quote.author,
      });
    })();
  }, []);

  if (!questionnaire) {
    return null;
  }

  return (
    <RequireProfileCompleted>
      <Box className="dashboard">
        <Typography
          className="dashboard-title"
          variant="h3"
        >
          El Círculo del Bienestar
        </Typography>

        <Box className="dashboard-content">
          <DashboardCircle
            levels={questionnaire.level1.filter(
              (level) => level.name !== 'Perfil'
            )}
            showGraphs={showGraphs}
            onClick={() => {
              setShowGraphs(!showGraphs);
            }}
          />
          <FormGroup className="dashboard-switch-group">
            <FormControlLabel
              control={
                <Switch
                  className="dashboard-switch"
                  checked={showGraphs}
                  onChange={(evt) => {
                    setShowGraphs(evt.target.checked);
                  }}
                />
              }
              label=""
            />
          </FormGroup>
        </Box>

        <Box className="dashboard-footer">
          <Box className="dashboard-footer-leaves">
            <LeavesImg />
          </Box>
          <Box
            className="dashboard-footer-phrase"
            onMouseDown={() => setShowQuote(true)}
            onMouseUp={() => setShowQuote(false)}
            onTouchStart={() => setShowQuote(true)}
            onTouchEnd={() => setShowQuote(false)}
          >
            <Box className="dashboard-footer-title">
              <Typography>Tu frase de hoy</Typography>
              <InfoIcon />
            </Box>
            <Typography className="dashboard-footer-quote">
              {dailyQuote.quote}
            </Typography>
          </Box>
        </Box>

        {showQuote && (
          <Box className="dashboard-modal-quote">
            <Typography
              className="dashboard-modal-quote-title"
              variant="h6"
            >
              Tu frase de hoy
            </Typography>
            <Typography
              className="dashboard-modal-quote-text"
              variant="h5"
            >
              {dailyQuote.quote}
              <br />
              <br />
              <span>
                &#8212;
                {dailyQuote.author}
              </span>
            </Typography>
          </Box>
        )}
      </Box>
    </RequireProfileCompleted>
  );
};

export default Dashboard;
