const PurposeIcon: React.FC = () => (
  <svg
    width="72"
    height="76"
    viewBox="0 0 72 76"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M63 47.0001L56.78 12.3401"
      stroke="#A54605"
      strokeWidth="2.94"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M6.88004 74.3H68.88C69.2593 74.3 69.623 74.1494 69.8912 73.8812C70.1594 73.613 70.31 73.2493 70.31 72.87V48.04L26.89 62.04L22.01 66.24L15.01 67.29L11.78 67.61L8.39004 69.27L2.00004 69.43C1.99739 70.0711 2.12187 70.7065 2.3663 71.2992C2.61072 71.8919 2.97024 72.4302 3.42405 72.8831C3.87786 73.336 4.41697 73.6944 5.01017 73.9376C5.60338 74.1808 6.23892 74.304 6.88004 74.3Z"
      fill="#D34F7A"
    />
    <path
      d="M41.3302 12.34C44.1855 12.34 46.5002 10.0253 46.5002 7.17C46.5002 4.31469 44.1855 2 41.3302 2C38.4748 2 36.1602 4.31469 36.1602 7.17C36.1602 10.0253 38.4748 12.34 41.3302 12.34Z"
      fill="white"
      stroke="#4FB3E2"
      strokeWidth="2.94"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M54.4 16.7C48.4091 18.0797 42.1956 18.1887 36.16 17.0199C36.16 17.0199 32.16 16.1299 30.09 21.0199C28.02 25.9099 22.82 32.8199 25.72 36.6899C28.62 40.5599 36.79 40.64 36.79 40.64C36.79 40.64 40.67 40.8099 40.67 46.4699C40.67 52.1299 41.8 53.33 43.98 53.33C46.16 53.33 46.5 50.33 46.5 48.89V39.8399C46.5 39.8399 46.92 34.9999 41.33 34.9999C35.74 34.9999 35.53 31.3599 36.16 29.9999C36.54 29.1699 37.16 27.5599 37.64 26.4099C37.7955 26.0111 38.0597 25.6638 38.4026 25.4075C38.7455 25.1512 39.1534 24.9961 39.58 24.9599C43.67 24.5799 55.3 23.1799 56.58 19.3399C57.47 16.6999 54.4 16.7 54.4 16.7Z"
      fill="white"
      stroke="#4FB3E2"
      strokeWidth="2.94"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M19.2801 20.5701L21.7801 16.5001C22.0991 15.9601 22.5283 15.4933 23.0396 15.13C23.551 14.7667 24.133 14.5151 24.748 14.3915C25.363 14.268 25.997 14.2752 26.609 14.4128C27.221 14.5504 27.7972 14.8152 28.3001 15.1901C29.2044 15.8799 29.8407 16.8627 30.0998 17.9702C30.3589 19.0777 30.2246 20.2408 29.7201 21.2601L25.34 30.2001C25.0602 30.7979 24.652 31.3267 24.1445 31.7487C23.637 32.1707 23.0426 32.4756 22.4038 32.6418C21.765 32.8079 21.0974 32.8312 20.4486 32.7099C19.7998 32.5886 19.1857 32.3258 18.6501 31.9401L18.0701 31.5301C17.1141 30.8195 16.4491 29.7856 16.1989 28.621C15.9487 27.4565 16.1304 26.2407 16.7101 25.2001L19.2801 20.5701Z"
      fill="#F29C00"
      stroke="#A54605"
      strokeWidth="2.94"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M27.5101 38.79C27.5101 38.79 28.1501 47.32 25.5101 54.34C25.5101 54.34 24.4101 57.41 26.6201 58.79C28.8301 60.17 33.6201 58.06 34.0001 40.44"
      fill="white"
    />
    <path
      d="M27.5101 38.79C27.5101 38.79 28.1501 47.32 25.5101 54.34C25.5101 54.34 24.4101 57.41 26.6201 58.79C28.8301 60.17 33.6201 58.06 34.0001 40.44"
      stroke="#4FB3E2"
      strokeWidth="2.94"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M2.07007 68.7201H7.58007C8.77526 68.733 9.94645 68.3844 10.9401 67.7201V67.7201C11.7986 67.1381 12.7955 66.7931 13.8301 66.7201L19.4601 66.2701C20.7039 66.1695 21.8854 65.6836 22.8401 64.8801L25.8401 62.3501C26.4277 61.8504 27.107 61.47 27.8401 61.2301L70.3601 47.3"
      stroke="#8C1A3C"
      strokeWidth="2.94"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M25.0701 74.3001L27.9701 72.6501C28.742 72.2179 29.3131 71.4996 29.5601 70.6501V70.6501C29.7188 70.079 30.0236 69.5591 30.4444 69.1418C30.8653 68.7244 31.3877 68.424 31.9601 68.2701L35.7001 67.2701C35.9846 67.1985 36.2767 67.1615 36.5701 67.1601H46.6601C47.4442 67.1598 48.204 66.8877 48.8101 66.3901L50.3901 65.1001C50.9953 64.601 51.7556 64.3287 52.5401 64.3301H61.0701"
      stroke="#8C1A3C"
      strokeWidth="2.94"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);

export default PurposeIcon;
