import Radio, { RadioProps } from '@mui/material/Radio';

interface RatingLevelProps {
  checked?: boolean;
}

const RatingA: React.FC<RatingLevelProps> = ({
  checked,
}) => (
  <svg
    className={`rating-level-option ${
      checked ? 'checked' : ''
    }`}
    width="53"
    height="48"
    viewBox="0 0 53 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M52.2962 0.206055H0V48H52.2962V0.206055Z"
      fill="#FCCB83"
    />
  </svg>
);

const RatingB: React.FC<RatingLevelProps> = ({
  checked,
}) => (
  <svg
    className={`rating-level-option ${
      checked ? 'checked' : ''
    }`}
    width="54"
    height="48"
    viewBox="0 0 54 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.3599 0.206055H0.948242V48H10.3599V0.206055Z"
      fill="#FCB542"
    />
    <path
      d="M21.2408 0.206055H11.8291V48H21.2408V0.206055Z"
      fill="#FCB542"
    />
    <path
      d="M32.1285 0.206055H22.7168V48H32.1285V0.206055Z"
      fill="#FCB542"
    />
    <path
      d="M43.0088 0.206055H33.5972V48H43.0088V0.206055Z"
      fill="#FCB542"
    />
    <path
      d="M53.8897 0.206055H44.478V48H53.8897V0.206055Z"
      fill="#FCB542"
    />
  </svg>
);

const RatingC: React.FC<RatingLevelProps> = ({
  checked,
}) => (
  <svg
    className={`rating-level-option ${
      checked ? 'checked' : ''
    }`}
    width="55"
    height="48"
    viewBox="0 0 55 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.2602 0.206055H0.443359V48H4.2602V0.206055Z"
      fill="#F29C00"
    />
    <path
      d="M9.25092 0.206055H5.43408V48H9.25092V0.206055Z"
      fill="#F29C00"
    />
    <path
      d="M14.2348 0.206055H10.418V48H14.2348V0.206055Z"
      fill="#F29C00"
    />
    <path
      d="M19.2255 0.206055H15.4087V48H19.2255V0.206055Z"
      fill="#F29C00"
    />
    <path
      d="M24.2163 0.206055H20.3994V48H24.2163V0.206055Z"
      fill="#F29C00"
    />
    <path
      d="M29.2001 0.206055H25.3833V48H29.2001V0.206055Z"
      fill="#F29C00"
    />
    <path
      d="M34.1909 0.206055H30.374V48H34.1909V0.206055Z"
      fill="#F29C00"
    />
    <path
      d="M39.1748 0.206055H35.3579V48H39.1748V0.206055Z"
      fill="#F29C00"
    />
    <path
      d="M44.1655 0.206055H40.3486V48H44.1655V0.206055Z"
      fill="#F29C00"
    />
    <path
      d="M49.1562 0.206055H45.3394V48H49.1562V0.206055Z"
      fill="#F29C00"
    />
    <path
      d="M54.1401 0.206055H50.3232V48H54.1401V0.206055Z"
      fill="#F29C00"
    />
  </svg>
);

const RatingD: React.FC<RatingLevelProps> = ({
  checked,
}) => (
  <svg
    className={`rating-level-option ${
      checked ? 'checked' : ''
    }`}
    width="56"
    height="48"
    viewBox="0 0 56 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.75317 0.206055H0.844727V48H2.75317V0.206055Z"
      fill="#DB8803"
    />
    <path
      d="M5.6843 0.206055H3.77588V48H5.6843V0.206055Z"
      fill="#DB8803"
    />
    <path
      d="M8.61594 0.206055H6.70752V48H8.61594V0.206055Z"
      fill="#DB8803"
    />
    <path
      d="M11.5403 0.206055H9.63184V48H11.5403V0.206055Z"
      fill="#DB8803"
    />
    <path
      d="M14.4714 0.206055H12.563V48H14.4714V0.206055Z"
      fill="#DB8803"
    />
    <path
      d="M17.3957 0.206055H15.4873V48H17.3957V0.206055Z"
      fill="#DB8803"
    />
    <path
      d="M20.3274 0.206055H18.4189V48H20.3274V0.206055Z"
      fill="#DB8803"
    />
    <path
      d="M23.2585 0.206055H21.3501V48H23.2585V0.206055Z"
      fill="#DB8803"
    />
    <path
      d="M26.1828 0.206055H24.2744V48H26.1828V0.206055Z"
      fill="#DB8803"
    />
    <path
      d="M29.114 0.206055H27.2056V48H29.114V0.206055Z"
      fill="#DB8803"
    />
    <path
      d="M32.0383 0.206055H30.1299V48H32.0383V0.206055Z"
      fill="#DB8803"
    />
    <path
      d="M34.9699 0.206055H33.0615V48H34.9699V0.206055Z"
      fill="#DB8803"
    />
    <path
      d="M37.9011 0.206055H35.9927V48H37.9011V0.206055Z"
      fill="#DB8803"
    />
    <path
      d="M40.8254 0.206055H38.917V48H40.8254V0.206055Z"
      fill="#DB8803"
    />
    <path
      d="M43.7566 0.206055H41.8481V48H43.7566V0.206055Z"
      fill="#DB8803"
    />
    <path
      d="M46.6814 0.206055H44.7729V48H46.6814V0.206055Z"
      fill="#DB8803"
    />
    <path
      d="M49.6125 0.206055H47.7041V48H49.6125V0.206055Z"
      fill="#DB8803"
    />
    <path
      d="M52.5437 0.206055H50.6353V48H52.5437V0.206055Z"
      fill="#DB8803"
    />
    <path
      d="M55.468 0.206055H53.5596V48H55.468V0.206055Z"
      fill="#DB8803"
    />
  </svg>
);

const RatingE: React.FC<RatingLevelProps> = ({
  checked,
}) => (
  <svg
    className={`rating-level-option ${
      checked ? 'checked' : ''
    }`}
    width="53"
    height="48"
    viewBox="0 0 53 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.985471 0H0.03125V47.7939H0.985471V0Z"
      fill="#A54605"
    />
    <path
      d="M3.06506 0H2.11084V47.7939H3.06506V0Z"
      fill="#A54605"
    />
    <path
      d="M5.14514 0H4.19092V47.7939H5.14514V0Z"
      fill="#A54605"
    />
    <path
      d="M7.22522 0H6.271V47.7939H7.22522V0Z"
      fill="#A54605"
    />
    <path
      d="M9.30529 0H8.35107V47.7939H9.30529V0Z"
      fill="#A54605"
    />
    <path
      d="M11.3854 0H10.4312V47.7939H11.3854V0Z"
      fill="#A54605"
    />
    <path
      d="M13.4655 0H12.5112V47.7939H13.4655V0Z"
      fill="#A54605"
    />
    <path
      d="M15.5455 0H14.5913V47.7939H15.5455V0Z"
      fill="#A54605"
    />
    <path
      d="M17.6324 0H16.6782V47.7939H17.6324V0Z"
      fill="#A54605"
    />
    <path
      d="M19.7125 0H18.7583V47.7939H19.7125V0Z"
      fill="#A54605"
    />
    <path
      d="M21.7926 0H20.8384V47.7939H21.7926V0Z"
      fill="#A54605"
    />
    <path
      d="M23.8727 0H22.9185V47.7939H23.8727V0Z"
      fill="#A54605"
    />
    <path
      d="M25.9528 0H24.9985V47.7939H25.9528V0Z"
      fill="#A54605"
    />
    <path
      d="M28.0328 0H27.0786V47.7939H28.0328V0Z"
      fill="#A54605"
    />
    <path
      d="M30.1124 0H29.1582V47.7939H30.1124V0Z"
      fill="#A54605"
    />
    <path
      d="M32.1925 0H31.2383V47.7939H32.1925V0Z"
      fill="#A54605"
    />
    <path
      d="M34.2726 0H33.3184V47.7939H34.2726V0Z"
      fill="#A54605"
    />
    <path
      d="M36.3526 0H35.3984V47.7939H36.3526V0Z"
      fill="#A54605"
    />
    <path
      d="M38.4327 0H37.4785V47.7939H38.4327V0Z"
      fill="#A54605"
    />
    <path
      d="M40.5197 0H39.5654V47.7939H40.5197V0Z"
      fill="#A54605"
    />
    <path
      d="M42.5997 0H41.6455V47.7939H42.5997V0Z"
      fill="#A54605"
    />
    <path
      d="M44.6798 0H43.7256V47.7939H44.6798V0Z"
      fill="#A54605"
    />
    <path
      d="M46.7599 0H45.8057V47.7939H46.7599V0Z"
      fill="#A54605"
    />
    <path
      d="M48.84 0H47.8857V47.7939H48.84V0Z"
      fill="#A54605"
    />
    <path
      d="M50.92 0H49.9658V47.7939H50.92V0Z"
      fill="#A54605"
    />
    <path
      d="M53.0001 0H52.0459V47.7939H53.0001V0Z"
      fill="#A54605"
    />
  </svg>
);

const RATING_LEVELS = [
  RatingA,
  RatingB,
  RatingC,
  RatingD,
  RatingE,
];

export interface RatingRadioProps extends RadioProps {
  level: number;
}

const RatingScaleRadio: React.FC<RatingRadioProps> = ({
  level,
  ...rest
}) => {
  const Component = RATING_LEVELS[level];

  return (
    <Radio
      sx={{
        '&:hover': {
          bgcolor: 'transparent',
        },
      }}
      className="questionable-rating-radio"
      disableRipple
      color="default"
      icon={<Component />}
      checkedIcon={<Component checked />}
      {...rest}
    />
  );
};

export default RatingScaleRadio;
