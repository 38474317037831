import { useContext } from 'react';

import { Navigate, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';

import QuestionDisplay from '../../components/Evaluations/QuestionDisplay';
import RequireProfileCompleted from '../../components/Shared/RequireProfileCompleted';

import { QuestionnaireContext } from '../../providers/QuestionnaireProvider';

import { selectEvaluationData } from '../../selectors';

import './style.scss';

const Evaluation: React.FC = () => {
  const { questionnaire, refetch } = useContext(
    QuestionnaireContext
  );

  const { level1Id, level2Id } = useParams() as {
    level1Id: string;
    level2Id: string;
  };

  if (!questionnaire) {
    return null;
  }

  const { level1, questions, progress } =
    selectEvaluationData(questionnaire, level1Id, level2Id);

  if (questions.length === 0) {
    return <Navigate to="/dashboard/evaluations" />;
  }

  return (
    <RequireProfileCompleted>
      <Box className="evaluation">
        <QuestionDisplay
          level1={level1}
          question={questions[0]}
          progress={progress}
          lastQuestion={questions.length === 1}
          onAnswered={async () => {
            await refetch();
          }}
        />
      </Box>
    </RequireProfileCompleted>
  );
};

export default Evaluation;
