import { useEffect } from 'react';
import {
  Navigate,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

import { useAuth } from '../../hooks';

const EmailVerify: React.FC = () => {
  const [searchParams] = useSearchParams();

  const auth = useAuth();

  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      if (!searchParams.has('token')) {
        return;
      }

      const token = searchParams.get('token') ?? '';
      await auth.loginWithToken(token);
      navigate('/dashboard', { replace: true });
    })();
  }, [auth, navigate, searchParams]);

  if (!searchParams.has('token')) {
    return <Navigate to="/" />;
  }

  return null;
};

export default EmailVerify;
